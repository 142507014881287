@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.floating-box {
  position: relative;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 19px solid transparent;
    border-right: 19px solid transparent;
    border-bottom: 11px solid #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    border-radius: $border-radius-default;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
  }

  &__wrapper {
    visibility: hidden;
    min-width: 322px;
    background: #fff;
    border: 1px solid $light-grey-color-200;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    border-radius: $border-radius-default;
    position: absolute;
    z-index: 0;
    opacity: 0;
    padding: 0;
    right: auto;
    pointer-events: none;

    &#{&}--left {
      left: 0;
      transform: translateY(-5px);
    }

    &#{&}--right {
      right: 0;
    }

    &#{&}--visible {
      transition: 200ms $ease-out-quad;
      visibility: visible;
      opacity: 1;
      transform: translateY(10px);
      z-index: $floating-box-z-index;
      pointer-events: initial;
    }
  }

  &__button {
    background: none;
    border: none;
    font-size: rem(16);
    color: $text-color-super-darken;
    font-weight: 300;
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    border-radius: $border-radius-default;

    &--has-notifications {
      position: absolute;
      left: 12px;
      top: -5px;
    }

    &:hover {
      color: $primary-color;

      .icon-marmita {
        margin: 0 5px 0 0;

        @include colorize-icon($primary-color);
      }
    }

    .icon-marmita {
      width: 20px;

      @include colorize-icon($text-color-super-darken);

      margin: 0 5px 0 0;
      height: 23px;
    }
  }
}
