@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-slim-card {
  display: flex;
  align-items: center;
  background-color: white;
  padding: $space-thin-blue $space-yellow;
  border: 1px solid $light-grey-color-200;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: $border-radius-default;
  height: 80px;
  width: 100%;

  @include remove-tap-highlight;

  &__icon {
    width: 34px;
    height: 34px;
  }

  &__header {
    padding-left: $space-thin-blue;
    display: flex;
    flex-direction: column;
  }

  &__cta {
    margin-left: auto;

    > button {
      border: 0;
      padding: 0;
      margin-right: 0;
      transform: rotate(90deg);

      > .icon-marmita {
        height: 15px;
        width: 15px;

        path {
          fill: $button-primary-color;
        }
      }
    }
  }

  &__title {
    font-size: rem(16);
    color: $text-color-super-darken;
    margin: 0;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
  }

  &__short-description {
    color: $text-color;
    font-size: rem(12);

    @include mq('mobile') {
      font-size: rem(14);
    }
  }

  &__expiration-date {
    color: $text-color-lighten;
    font-size: rem(14);
    text-align: right;
  }
}
