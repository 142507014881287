@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-tile-carousel-loading {
  display: flex;

  &__item {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $light-grey-color-100;
    min-width: 156px;
    margin-right: 12px;
  }

  &__image {
    width: 56px;
    height: 56px;
    border-radius: 50% !important;
  }

  &__name {
    margin-top: 12px;
    height: 18px;
  }

  &__delivery-info {
    margin-top: 4px;
    height: 16px;
  }
}
