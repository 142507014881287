@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-checkbox {
  transition: all 100ms;
  border-radius: 5px;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  padding: 0;
  outline: none;
  position: relative;
  user-select: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  width: 24px;
  height: 24px;
  background-color: transparent;
  z-index: 0;

  &:hover {
    background-color: transparent;
  }

  &::before,
  &::after {
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    background: transparent;
    transform: translate(-50%, -50%);
    border-radius: $border-radius-default;
  }

  &::before {
    transition: border 100ms;
    z-index: -2;
    border-style: solid;
  }

  &::after {
    transition: all 100ms;
    z-index: -1;
    transform: translate(-50%, -50%) scale(0);
  }

  &__input {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }

  &__icon {
    transition: all 200ms;
    transform: scale(0) translateY(3px);

    svg {
      width: 28px;
      height: 28px;
      display: inline-flex;
    }
  }

  &--primary {
    &::before {
      transition-delay: 130ms;
      border: 2px solid $medium-grey-color;
    }

    &::after {
      background: $primary-color;
    }

    .marmita-checkbox__icon {
      @include colorize-icon(#fff);
    }
  }

  &--tertiary {
    &::before {
      transition-delay: 130ms;
      border: 2px solid $tertiary-color;
    }

    &::after {
      background: $tertiary-color;
    }

    .marmita-checkbox__icon {
      @include colorize-icon($primary-color);
    }
  }

  &--checked {
    &.marmita-checkbox--primary {
      &::before {
        transition-delay: 0;
        border-color: $medium-grey-color;
      }

      &::after {
        transition-delay: 200ms;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &.marmita-checkbox--tertiary {
      background: $tertiary-color;

      &::before {
        transition-delay: 0;
        border-color: $tertiary-color;
      }

      &::after {
        transition-delay: 200ms;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    .marmita-checkbox__icon {
      transition-delay: 250ms;
      transform: scale(1) translateY(3px);
    }
  }

  &--disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.4;
  }

  &--small {
    width: 20px;
    height: 20px;

    &.marmita-checkbox--checked {
      &.marmita-checkbox--primary {
        &::before {
          border-width: 10px;
        }
      }
    }
  }

  &--normal {
    width: 24px;
    height: 24px;

    &.marmita-checkbox--checked {
      &.marmita-checkbox--primary {
        &::before {
          border-width: 12px;
        }
      }
    }
  }
}
