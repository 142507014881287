@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.notification-overlay {
  background: rgb(0 0 0 / 70%);
  color: #fff;

  .marmita-modal__inner-content {
    display: none;
    max-width: 500px;

    @include mq('desktop-s') {
      display: block;
    }
  }

  &__title {
    font-size: rem(30);
  }

  &__subtitle {
    font-size: rem(20);
    font-weight: 100;
  }
}
