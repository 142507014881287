@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.promotional-tag {
  color: $promotion-tag-color;
  display: flex;
  font-size: rem(12);
  font-weight: normal;
  margin: 5px 0;

  .icon-marmita {
    @include colorize-icon($promotion-tag-color);

    margin-right: 3px;
    width: 12px;
    top: -2px;
  }
}
