@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.toast {
  position: absolute;
  display: grid;
  grid-template-columns: 10px 1fr 33px;
  gap: 16px;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 480px;
  height: auto;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: $border-radius-default;
  padding: 9px 12px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  color: white;
  font-size: rem(14);
  line-height: rem(18);
  transition: all ease-in 150ms;
  pointer-events: none;
  z-index: 3;

  .icon-marmita {
    height: 14px;
    width: 14px;
    border-radius: 100%;

    svg {
      height: 18px;
      width: 18px;
      transform: translate(-2px, -2px);
    }
  }

  &--active {
    animation: fade-in $animation-duration;
    pointer-events: unset;
  }

  &--success {
    background: $green-color;
  }

  &--error {
    background: $primary-color-lighten;
  }

  .btn-icon {
    right: 0;
    background: transparent;
    opacity: 0.6;
    height: 20px;

    &:hover {
      background: transparent;
    }
  }
}
