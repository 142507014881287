@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-cart-header {
  margin: $padding-default 45px;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include mq('desktop-s') {
    padding: 0;
  }

  &__pretitle {
    color: $text-color;
    font-weight: 300;
    font-size: rem(14);
    width: 100%;
  }

  &__title {
    margin: 8px 0 0;
    font-size: rem(18);
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    max-width: 70%;
  }

  &__link {
    height: 25px;
    padding: 0;
    margin-top: 8px;
    font-size: rem(14);
    font-weight: 400;

    .btn__label {
      text-align: left;
    }
  }
}
