@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-cart-group {
  &__name {
    padding: 18px 30px;
    margin: 0;
    font-weight: 500;
    font-size: rem(14);

    @include mq('desktop-s') {
      padding: 0 30px 10px;
    }
  }
}
