@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.related-search-carousel-loading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $space-pink $space-yellow $space-yellow;
  min-height: 110px;

  &__title {
    background-color: $light-grey-color-100;
    height: 16px;
    width: 150px;
    margin-bottom: $space-yellow;
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }

  &__option {
    background-color: $light-grey-color-100;
    border-radius: 38px;
    margin-bottom: $space-thin-pink;
    height: 36px;
    width: 165px;

    &:not(:last-child) {
      margin-right: $space-thin-pink;
    }
  }

  @include mq('desktop-s') {
    flex-direction: row;
    align-items: center;
    padding: $space-pink 0 $space-yellow;

    &__title {
      margin-right: $space-orange;
    }
  }
}
