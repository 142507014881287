@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.btn {
  background: transparent;
  text-decoration: none;
  color: $button-primary-color;
  border: none;
  padding: 0 20px;
  border-radius: $border-radius-default;
  margin: 3px 0;
  height: 50px;
  font-weight: 500;
  transition: 100ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: default;

  @include mq('desktop-s') {
    cursor: pointer;
  }

  // for the touch to detect the ripple up in span inside the button it is necessary this z-index;
  & > canvas {
    z-index: 1;

    @include mq('tablet') {
      visibility: hidden;
    }
  }

  .btn__label {
    font-size: rem(16);
    line-height: em(16);
    flex-grow: 1;
    position: relative;
    top: 1px;
    text-align: center;
  }

  &:focus {
    // this we will find a solution for accessibility along with the designer.
    // @see https://a11yproject.com/posts/never-remove-css-outlines/
    outline: transparent;
  }

  @include mq('desktop-s') {
    &:active {
      opacity: 0.8;
    }
  }

  &[disabled] {
    cursor: no-drop;
    pointer-events: none;
  }

  &[disabled],
  &[aria-disabled='true'] {
    opacity: 0.5;

    & > canvas {
      visibility: hidden;
    }
  }

  // THEME DEFAULT
  &--default {
    background: $button-primary-color;
    color: #fff;

    @include colorize-icon(#fff);

    &[disabled],
    &[aria-disabled='true'] {
      background: color.adjust($button-primary-color, $lightness: 20%);
      color: #fff;

      @include colorize-icon(#fff);
    }

    &.btn--loading {
      &[disabled],
      &[aria-disabled='true'] {
        background: $button-primary-color;
        color: $button-secondary-color;
      }
    }

    @include mq('desktop-s') {
      &:hover:enabled,
      &:hover {
        background: color.adjust($button-primary-color, $lightness: 10%);
      }

      &.btn--secondary:hover:enabled {
        background: color.adjust($button-secondary-color, $lightness: 10%);
      }

      &.btn--facebook:hover:enabled {
        background: color.adjust($facebook-color, $lightness: 10%);
      }

      &.btn--white:hover:enabled {
        color: #fff;
        background: $button-primary-color;

        @include colorize-icon(#fff);
      }
    }
  }

  &--facebook {
    background: $facebook-color;
    color: #fff;

    @include colorize-icon(#fff);

    &[disabled],
    &[aria-disabled='true'] {
      background: color.adjust($facebook-color, $lightness: 20%);
      color: #fff;
      opacity: 1;
    }

    &.btn--loading {
      &[disabled],
      &[aria-disabled='true'] {
        background: color.adjust($facebook-color, $lightness: 20%);
        color: #fff;
        opacity: 1;
      }
    }
  }

  &--secondary {
    background: $button-secondary-color;
    color: $button-primary-color;

    @include colorize-icon($button-primary-color);
  }

  &--gray {
    background: $gray-color-lighten;
    color: #fff;
  }

  &--white {
    background: #fff;
    color: $button-primary-color;

    @include colorize-icon($button-primary-color);
  }

  &--transparent {
    background: transparent;
    color: $text-color;
    border: 1px solid $light-grey-color-300;

    @include mq('desktop-s') {
      &:hover:enabled {
        background: $light-grey-color-200;
      }
    }

    &--active {
      background: $light-grey-color-200;
    }

    &--selected {
      color: #fff;
      background: $text-color;

      @include mq('desktop-s') {
        &:hover:enabled {
          background: $text-color;
        }
      }
    }
  }

  // THEME LINK
  &--link {
    background: transparent;
    color: $button-primary-color;

    @include colorize-icon($button-primary-color);

    @include mq('desktop-s') {
      &:hover {
        text-decoration: none;
      }
    }

    &.btn--secondary {
      color: $button-secondary-color;

      @include colorize-icon($button-secondary-color);
    }

    &.btn--tertiary {
      color: $tertiary-color;

      @include colorize-icon($tertiary-color);
    }

    &.btn--gray {
      color: $gray-color-lighten;

      @include colorize-icon($gray-color-lighten);
    }

    &.btn--facebook {
      background: #fff;
      color: $facebook-color;

      @include colorize-icon($facebook-color);
    }
  }

  &--dark-link {
    background: transparent;
    color: $text-color-super-darken;
    font-size: rem(16);
    line-height: em(20);
  }

  &--underline {
    background: transparent;
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: transparent 3px solid;
    transition: 0.2s;

    &:hover {
      border-bottom-color: currentcolor;
    }
  }

  // With Icon
  &.btn--iconize {
    .btn__icon {
      width: 30px;
      height: 30px;
    }

    &.btn--iconize-right {
      flex-direction: row-reverse;
    }
  }

  &.btn--full-width {
    width: 100%;
  }

  // SIZES
  &.btn--size-l {
    height: 60px;
    padding: 0 40px;

    .btn__label {
      font-size: rem(20);
      line-height: em(20);
    }

    .btn__icon {
      transform: scale(1.6);
    }
  }

  &.btn--size-s {
    height: 30px;
    padding: 0 10px;

    .btn__label {
      font-size: rem(14);
      line-height: em(14);
    }

    .btn__icon {
      transform: scale(0.7);
    }
  }

  &.btn--loading {
    .marmita-spinner {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: currentcolor;
        top: 4px;
        position: relative;
      }
    }

    .btn__label {
      visibility: hidden;
    }
  }

  &--filter {
    background-color: #0000;
    color: $text-color;
    border: 1px solid $light-grey-color-300;
    border-radius: 20px;

    @include mq('desktop-s') {
      &:hover:enabled {
        background-color: $light-grey-color-200;
        transition: 0.6s;
      }
    }

    &--active {
      &:hover:enabled {
        background-color: $bubblegum-darken;
      }
    }

    &--selected {
      background-color: $bubblegum;
      color: $primary-color;
      border: 1px solid $bubblegum;

      &:hover:enabled {
        background-color: $bubblegum-darken;
      }
    }
  }
}
