@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-section-container {
  & + & {
    margin-top: 40px;
  }

  & + .cardstack-filter {
    margin-top: 44px !important;
  }
}
