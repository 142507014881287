@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.progress-bar {
  border-radius: 10px;
  background-color: $light-grey-color-300;
  width: 100%;
  height: 3px;

  &__current-progress {
    border-radius: 10px;
    background-color: $green-color;
    height: 3px;
    animation: animate-progressBar 1s ease-in-out 0.5ms;
  }
}
