@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-counter {
  display: flex;
  align-items: center;

  &__value {
    display: inline-block;
    height: 30px;
    min-width: 35px;
    line-height: 34px;
    text-align: center;
    font-size: rem(18);
    font-weight: 500;
    margin: 3px 0;
  }

  &--minimal {
    .marmita-counter__btn-decrement {
      visibility: hidden;
    }

    .marmita-counter__value {
      visibility: hidden;
    }
  }

  .btn-icon {
    margin-left: 0;
    margin-right: 0;
  }
}
