@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.desktop-category-selector {
  .floating-box__wrapper {
    min-width: 126px;
  }

  &__floating-box {
    display: none;
  }

  &__floating-menu {
    .floating-box__label {
      display: flex;
      align-items: center;
      color: $primary-color;
      font-weight: 500;
    }
  }

  &__label-arrow {
    width: 20px;
    height: 20px;
  }

  &__vertical-item {
    & + & {
      margin: 10px 0 0;
    }
  }

  @include mq('desktop', max) {
    &__floating-box {
      display: block;
    }

    &__horizontal {
      display: none;
    }
  }
}
