@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.error-information-dialog {
  width: 100%;

  @include mq('desktop-s') {
    width: 510px;
  }

  .marmita-modal__actions {
    margin: rem(10);

    button {
      .icon-marmita--close {
        svg {
          fill: #ea1d2c;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: rem(30);
    padding: rem(10) rem(20) rem(0) rem(20);

    @include mq('desktop-s') {
      padding: rem(4) rem(40);
    }
  }
}
