@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-guide {
  background-color: $light-grey-color-200;
  border-radius: 0 0 $border-radius-default $border-radius-default;
  padding: 12px;
  margin: 0 20px;
  display: grid;
  grid-template-areas:
    'title action'
    'progress action';
  grid-template-columns: 1fr min-content;
  grid-gap: 5px 16px;

  @include mq('desktop-s') {
    margin: 0 45px 18px;
  }

  &__content-title {
    display: grid;
    align-items: center;
    grid-template: 1fr / 1fr 70px;
    grid-auto-columns: auto;
    grid-auto-flow: column dense;
    padding: 0 0 6px;
  }

  &__title {
    font-size: rem(12);
    line-height: rem(16);
    color: $text-color;
    grid-area: title;
  }

  &__complete-order {
    font-size: rem(12);
    line-height: rem(16);
    color: $primary-color;
    text-decoration: none;
    text-align: right;
    grid-area: action;
    align-self: center;
  }

  &__progress {
    grid-area: progress;
    width: 100%;
  }
}
