@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 $padding-default;
  color: $input-color;

  @include mq('tablet') {
    width: 500px;
    padding: 20px 50px;
  }

  &__title {
    font-size: rem(18);
    font-weight: 500;
    text-align: center;

    @include mq('tablet') {
      font-size: rem(24);
      text-align: left;
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    font-size: rem(14);
    font-weight: lighter;
    line-height: 18px;
    padding: 20px 40px;

    @include mq('tablet') {
      font-size: rem(16);
      padding: 20px 0;
      text-align: left;
    }

    & > span {
      margin-bottom: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
