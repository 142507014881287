@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.image-banner-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-grey-color-100;
  transition: 200ms;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  text-decoration: none;
  width: 100%;
  margin: 20px 0;
  max-height: 173px;

  @include mq('mobile-s') {
    height: 72px;
  }

  @include mq('mobile-l') {
    height: 74px;
  }

  @include mq('tablet') {
    height: 140px;
  }

  @include mq('desktop-s') {
    height: 173px;
  }

  &__container {
    max-width: 900px;
    margin: 0 auto;
  }

  &__figure {
    height: 70%;
    width: 90%;
  }
}
