@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.text-area {
  display: flex;
  flex-direction: column;

  &__input {
    background: #fff;
    border: 1px solid $light-grey-color-300;
    box-sizing: border-box;
    border-radius: rem(4);
    padding: rem(15) rem(12);
    resize: none;
    height: rem(136);

    &::placeholder {
      color: $medium-grey-color;
    }
  }

  &__label {
    color: $medium-grey-color;
    width: fit-content;
    margin: rem(4) 0 0 auto;
    font-size: rem(12);
    line-height: rem(16);
  }

  &__error {
    width: 85%;
    margin-top: -1rem !important;
    font-size: rem(12);
    color: $primary-color-lighten;
    white-space: break-spaces;
  }

  &__invalid {
    .text-area__input {
      border-color: $primary-color-lighten;
    }
  }
}
