@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.btn-selected {
  background: transparent;
  text-decoration: none;
  color: $input-color;
  border: 1px solid $light-grey-color-100;
  border-radius: $border-radius-default;
  font-weight: 500;
  transition: 100ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  height: 50px;

  &__label {
    font-size: rem(14);
  }
}
