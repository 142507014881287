@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.small-banner-carousel-loading {
  display: flex;
  height: 82px;
  justify-content: space-between;
  margin-top: 30px 0;

  @include mq('mobile-l') {
    height: 84px;
  }

  @include mq('tablet') {
    height: 86px;
  }

  @include mq('desktop-s') {
    height: 104px;
  }

  &__item {
    width: 76px;
    height: 62px;
    margin-left: 12px;

    &:first-child {
      margin: 0;
    }

    @include mq('desktop-s') {
      width: 100px;
      height: 80px;
      margin-left: 24px;
    }
  }
}
