@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.search-input {
  width: 100%;

  @include mq('desktop-s') {
    max-width: 320px;
    position: relative;
  }

  @include mq('desktop') {
    max-width: 420px;
  }

  @include mq('desktop-wide') {
    max-width: 469px;
  }

  &__divisor {
    min-width: 320px;

    @include mq('desktop-wide') {
      min-width: 469px;
    }
  }

  &__icon-search {
    width: 24px;
    height: 24px;

    @include colorize-icon($primary-color);

    @include mq('desktop-s') {
      width: 26px;
      height: 26px;
      left: 20px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      pointer-events: none;

      @include colorize-icon($dark-black-color);
    }
  }

  &__form {
    display: grid;
    grid-template: 1fr / 1fr;
    height: 40px;
    position: relative;

    @include mq('desktop-s') {
      height: 100%;
    }
  }

  .btn {
    padding: 0 0 0 10px;
    margin: 0;

    &-visibility {
      &--hidden {
        visibility: hidden;
      }
    }
  }

  &__field-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 5px 0 12px;
    border-radius: 8px;
    margin-right: 0;
    width: 100%;
    position: relative;

    @include mq('desktop-s') {
      padding: 0;
    }

    .search-input__icon-search {
      width: 22px;
      height: 22px;

      @include colorize-icon($primary-color);

      @include mq('desktop-s') {
        left: 15px;
      }
    }
  }

  &__field {
    width: 100%;
    height: 100%;
    padding-left: 12px;
    border: none;
    font-size: rem(16);
    background-color: $light-grey-color-200;

    @include mq('desktop-s') {
      display: flex;
      height: 48px;
      padding-left: 50px;
      padding-right: 0;
      line-height: em(20);
      font-weight: 300;
      border-radius: 8px;
      min-width: 320px;
    }

    @include mq('desktop-wide') {
      min-width: 440px;
    }

    &::placeholder {
      color: $search-placeholder-home;
      margin: 0;
      font-weight: 400;
      font-size: rem(16);

      @include mq('desktop-s') {
        font-weight: 300;
      }
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &__erase-button {
    @include mq('desktop-s') {
      background: transparent;
      border: none;
      margin: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
