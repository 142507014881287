@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.form-input-without-border {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 4px 0 8px;
  z-index: 0;
  width: 80%;
  margin: 5px auto 16px;

  %label-focused {
    transform: translateY(-20px);
    font-size: rem(16);

    @include mq('desktop-s') {
      font-size: rem(14);
      transform: translateY(-23px);
      background: #fff;
      z-index: 1;
      padding: 5px;
    }
  }

  @include mq('tablet', max) {
    width: 100%;
  }

  .form-input-without-border__label {
    background: $input-background-color;
    padding: 15px 0 0;
    display: flex;
    flex-direction: column;
    color: $input-placeholder-color;
    position: relative;

    @include mq('desktop-s') {
      padding: 0;
    }

    .label__name {
      display: none;
    }

    .field__border {
      transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
      height: 2px;
      width: 0;
      margin: auto;
      display: block;
      background: $input-border-bottom;
      position: relative;
      top: -2px;
      z-index: 1;

      @include mq('desktop-s') {
        display: none;
      }
    }

    &.form-input-without-border__label--has-value {
      .label__name {
        @extend %label-focused;
      }
    }
  }

  &__field {
    border: 0;
    letter-spacing: 20px;
    font-size: 40px;
    text-align: center;
    color: $input-color;
    background: transparent;
    font-weight: 500;
    outline: none;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 5px 20px;
    line-height: 1;
    border-bottom: 1px solid $light-grey-color;

    &:focus + .label__name {
      @extend %label-focused;

      & + .field__border {
        width: 100%;
      }
    }

    @include mq('tablet', max) {
      height: 70px;
      font-size: 32px;
    }

    &:focus {
      & + .form-input-without-border__erase-button {
        transition: visibility;
        transition-delay: 50ms;
        visibility: visible;
      }
    }

    &:-webkit-autofill {
      box-shadow: 0 30px 0 0 $input-background-color inset;
    }
  }

  .form-input-without-border__field[disabled] {
    opacity: 0.4;
  }

  .form-input-without-border__error {
    font-size: rem(12);
    margin: 4px 0 0;
    color: $danger-color;
  }

  &.form-input-without-border--invalid {
    .form-input-without-border__field {
      border-color: $danger-color;
    }

    .field__border {
      background: $danger-color;
    }
  }

  &.form-input-without-border--tertiary {
    .form-input-without-border__field {
      border-color: $tertiary-color;
      color: #fff;
    }

    .field__border {
      background: $tertiary-color;
    }

    .form-input-without-border__label {
      color: $tertiary-color;
    }

    .form-input-without-border__error {
      color: #fff;
    }

    &.form-input-without-border--invalid {
      .form-input-without-border__field {
        border-color: #fff;
      }

      .field__border {
        background: #fff;
      }
    }
  }

  &__erase-button {
    position: absolute;
    right: -20px;
    margin-top: -5px;
    transition: visibility;
    transition-delay: 50ms;
    visibility: hidden;

    @include mq('desktop-s') {
      right: 0;
      margin-top: 3px;
      cursor: pointer;
    }

    .icon-marmita {
      @include colorize-icon($tertiary-color);

      width: 25px;
    }
  }
}
