@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.experimental-feature {
  &::before {
    content: 'EF';
    width: 12px;
    font-size: 9px;
    color: #fff;
    background: #f90;
    position: absolute;
    padding: 5px 10px 3px;
    border-radius: 10px;
    text-transform: uppercase;
    transition: 0.4s;
    z-index: 99999999;
    white-space: nowrap;
  }

  &:hover {
    &::before {
      content: 'experimental feature';
      width: 114px;
    }
  }

  + * {
    border: 1px solid #f7990257;
    border-radius: 5px;
  }
}
