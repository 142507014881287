@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.featured-merchant-list-loading {
  $featured-merchant-width: 100%;
  $featured-merchant-height: 150px;

  border: 1px solid $light-grey-color-100;
  display: grid;
  transition: 200ms;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  text-decoration: none;
  width: $featured-merchant-width;
  grid-template-columns: 1fr;
  grid-template-areas:
    'figure figure'
    'content content';
  grid-column-gap: 12px;

  @include mq('desktop-s') {
    width: $featured-merchant-width;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @include mq('desktop-s') {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__figure {
    grid-area: figure;
    height: $featured-merchant-height;
  }

  &__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 1fr 20px;
    margin-left: 15px;
    margin-bottom: 8px;
  }

  &__header {
    margin-top: 8px;
    width: 85%;
    height: 18px;
    margin-bottom: 6px;
  }

  &__info {
    width: 65%;
    height: 14px;
  }

  &__footer {
    width: 45%;
    height: 14px;
  }
}
