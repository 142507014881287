@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.slider {
  &__title {
    font-size: rem(18);
    color: $black-color;
  }

  &__label {
    font-size: rem(14);
    color: $medium-grey-color;
  }

  &__value {
    color: #3e3e3e;
    font-size: rem(14);
    line-height: rem(20);
  }

  .slider-input {
    appearance: none;
    height: 2px;
    width: 100%;
    background: #f1f1f1;
    outline: none;

    &__fill {
      height: 2px;
      background-color: $primary-color;
      position: relative;
      transform: translateY(-7px);
      pointer-events: none;
    }
  }

  .slider-input::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: $primary-color;
    border-radius: 100%;
    border: none;
    cursor: grab;
    transition: 200ms $ease-out-circ;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      cursor: grabbing;
      transform: scale(1.2);
    }

    &:focus {
      border-color: 0;
      box-shadow: none;
    }
  }

  .slider-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: $primary-color;
    border: none;
    cursor: grab;
    transition: 200ms $ease-out-circ;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      cursor: grabbing;
      transform: scale(1.2);
    }

    &:focus {
      border-color: 0;
      box-shadow: none;
    }
  }
}
