@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-radio {
  transition: all 100ms;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  padding: 0;
  outline: none;
  position: relative;
  user-select: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  width: $radio-size;
  height: $radio-size;
  background-color: transparent;
  z-index: 0;

  &:hover {
    background-color: transparent;
  }

  &::before,
  &::after {
    box-sizing: border-box;
    content: '';
    width: $radio-size;
    height: $radio-size;
    position: absolute;
    left: 50%;
    top: 50%;
    background: transparent;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &__input {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: $radio-size;
    height: $radio-size;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
  }

  &::after {
    transition: 150ms $ease-out-quad;
    visibility: hidden;
  }

  &--primary {
    &::before {
      z-index: 0;
      background: $light-grey-color;
    }

    &::after {
      border: 0 solid $primary-color;
      background: transparent;
      z-index: 1;
    }
  }

  &--checked {
    &::after {
      border-width: 6px;
      visibility: visible;
    }
  }

  &--disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.4;
  }

  &--small {
    width: $radio-size-small;
    height: $radio-size-small;
  }

  &--normal {
    width: $radio-size;
    height: $radio-size;
  }
}
