@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-cart-item-price {
  &--promotional {
    color: $green-color;
  }

  &--original {
    font-size: rem(12);
    line-height: rem(20);
    color: $text-color;
    text-decoration: line-through;
    margin-left: 5px;

    @include mq('desktop') {
      font-size: rem(14);
    }
  }

  &--block {
    display: block;
  }
}
