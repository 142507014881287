@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.filter-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  min-width: 17px;
  top: 2px;
  padding: 2px 4px 0;
  border-radius: 19px;
  border: none;
  background: $primary-color;
  color: white;
  font-size: rem(11);
  line-height: rem(11);
  font-weight: 400;
}
