@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-tab {
  &__tablist-container {
    width: 100%;
    border-bottom: 1px solid $light-grey-color;
    padding: 0 $padding-default;

    @include mq('desktop-s') {
      padding: 0;
    }
  }

  &__tablist {
    display: flex;
    flex-wrap: nowrap;

    @include mq('desktop-s') {
      display: inline-block;
    }
  }

  &__panel-container {
    overflow: hidden;
  }

  &__panel {
    &--from-left {
      animation: slide-in-from-left 200ms;
    }

    &--from-right {
      animation: slide-in-from-right 200ms;
    }
  }

  &__label {
    flex-grow: 1;
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: transparent 2px solid;
    transition: 0.2s;
    color: #838282;

    &:hover {
      border-bottom: #a6a6a6 2px solid;
    }

    &--active {
      border-bottom-color: currentcolor;
      color: $text-color-super-darken;
    }
  }
}
