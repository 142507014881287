@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
@keyframes z-1-movement {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, -5px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes z-2-movement {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, 10px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes z-3-movement {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-5px, 12px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes voucher-dialog-animation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate(0);
  }
}

.restaurant-cart {
  display: flex;
  flex-flow: nowrap column;
  position: sticky;
  top: 0;
  width: $cart-width;
  background-color: #fff;
  color: $black-color;

  @include mq('desktop') {
    width: $cart-width;
  }

  &__warning {
    background-color: #f7f7f7;
    color: #4d4d4d;
    font-weight: 300;
    line-height: 1.4;
    padding: 24px 50px;
  }

  &__line {
    border-top: $light-grey-color-300 solid 1px;
    margin: 0 $padding-default-desktop;
    padding: 12px 0 16px;

    @include mq('desktop-s') {
      padding: 10px 0;
    }

    @media (min-height: 800px) {
      padding: 12px 0 16px;
    }
  }

  &__justify {
    display: flex;
    flex-flow: row nowrap;

    > * {
      flex-grow: 1;
    }

    > :last-child {
      min-width: 80px;
      text-align: right;
    }
  }

  &__loading,
  &__empty {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  &__empty {
    color: #dcdcdc;

    .restaurant-card-empty-icon {
      height: 270px;
      width: auto;
      margin: 0 auto 32px;
    }

    .restaurant-cart__empty--large {
      font-size: rem(16);
      color: $text-color-super-darken;
      font-weight: 500;
    }

    .restaurant-cart__empty--small {
      font-size: rem(14);
      font-weight: 400;
      color: $text-color;
      margin: 10px 0 0;
    }

    .animated-svg {
      .z-1 {
        transform-origin: top center;
        animation: z-1-movement 4.5s infinite;
        animation-timing-function: ease-in-out;
      }

      .z-2 {
        transform-origin: bottom center;
        animation: z-2-movement 6s infinite;
        animation-timing-function: ease-in-out;
      }

      .z-3 {
        transform-origin: bottom center;
        animation: z-3-movement 5s infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .restaurant-cart-items-list {
    @include custom-scrollbar(6px, $light-grey-color-100);
  }

  .voucher-dialog {
    height: 100%;
    animation: voucher-dialog-animation 200ms;
    animation-timing-function: ease-in-out;
    padding: 0 30px;

    .voucher-dialog-content {
      height: calc(100% - 80px);
    }

    .voucher-dialog-header {
      padding: $padding-default;
      margin: 10px 10px 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    &--scroll {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      @include custom-scrollbar(6px, $light-grey-color-100);
    }
  }
}

.floating-box {
  .restaurant-cart {
    overflow: hidden;

    .voucher-dialog .voucher-dialog-content {
      height: calc(645px - 62px);
    }
  }
}
