@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.btn-navigation {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px $padding-default;
  text-decoration: none;
  height: 60px;
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;

  @include mq('desktop-s') {
    padding: 10px 40px;

    &:hover {
      .btn-navigation__icon {
        @include colorize-icon($primary-color);

        .icon-marmita__shield-icon {
          stroke: $primary-color;
          fill: none;
        }
      }

      .btn-navigation__label {
        color: $primary-color;
      }
    }
  }

  canvas {
    color: $medium-grey-color;
  }

  &__icon {
    width: 25px;
    height: 28px;
    margin: 0 20px 0 0;

    @include colorize-icon(#7f7f82);

    @include mq('desktop-s') {
      margin: 0 45px 0 0;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .icon-marmita__shield-icon {
      stroke: #7f7f82;
      fill: none;
    }

    .counter-badge-wrapper {
      position: relative;

      .counter-badge-content {
        position: absolute;
        top: -34px;
        left: 14px;
        font-size: rem(10);
        background: $primary-color;
        color: #fff;
        height: 18px;
        text-align: center;
        border: solid 2px #fff;
        padding: 0 3px;
        line-height: 14px;
        border-radius: 18px;
        font-weight: 500;
      }
    }
  }

  &--counter-present {
    .btn-navigation__icon {
      @include colorize-icon($primary-color);
    }

    .btn-navigation__label {
      color: $primary-color;
    }
  }

  &__content {
    @include colorize-icon($text-color-lighten);

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: $text-color-lighten;
  }

  &__unavailable {
    font-size: rem(14);
  }

  &__label {
    font-size: rem(18);
    color: $text-color;
  }

  &__icon-arrow {
    margin: 0 0 0 auto;
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;

    @include colorize-icon($text-color);

    @include mq('desktop-s') {
      display: none;
    }
  }
}

.btn-navigation-unavailable {
  @include colorize-icon($text-color-lighten);

  .btn-navigation__label {
    color: $text-color-lighten;
  }

  &:hover {
    .btn-navigation__icon {
      @include colorize-icon($text-color-lighten);

      .icon-marmita__shield-icon {
        stroke: $text-color-lighten;
        fill: none;
      }
    }

    .btn-navigation__label {
      color: $text-color-lighten;
    }
  }

  @include mq('desktop-s') {
    padding: 10px 40px;
  }
}
