@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.something-wrong {
  padding: 40px 0;

  @include mq('tablet', max) {
    border-bottom: 1px solid $light-grey-color-200;

    &:last-child {
      border-bottom: none;
    }
  }

  &__image {
    > svg {
      margin: 0 auto;
      display: block;
      width: 260px;
      height: 260px;

      .bg-falu-red {
        fill: $falu-red;
      }

      .bg-ligth-pink {
        fill: $ligth-pink;
      }

      .bg-ziggurat {
        fill: $ziggurat;
      }

      .bg-carissma {
        fill: $carissma;
      }

      .bg-london-hue {
        fill: $london-hue;
      }

      .bg-crimson {
        fill: $crimson;
      }

      .bg-charm {
        fill: $charm;
      }

      .bg-blush {
        fill: $blush;
      }

      .something-went-wront-flip-flops-1 {
        animation: something-went-wrong-flip 6s infinite ease-in-out;
      }

      .something-went-wront-flip-flops-2 {
        animation: something-went-wrong-flip-2 6s infinite ease-in-out;
      }
    }
  }

  &__title,
  &__text {
    text-align: center;
  }

  &__title {
    color: $text-color-super-darken;
    font-size: rem(16);
    margin: 10px auto;
    line-height: rem(20);
    max-width: 280px;
  }

  &__text {
    color: $text-color;
    font-weight: 300;
    font-size: rem(14);
    margin: 0 auto;
    max-width: 280px;

    @include mq('tablet') {
      max-width: 320px;
      line-height: rem(18);
    }
  }

  &__btn-go-back {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px;

    span {
      color: #fff;
    }
  }
}

@keyframes something-went-wrong-flip {
  0% {
    transform: translate(8px, 8px);
  }

  50% {
    transform: translate(16px, 20px);
  }

  100% {
    transform: translate(8px, 8px);
  }
}

@keyframes something-went-wrong-flip-2 {
  0% {
    transform: translate(30px, 62px);
  }

  50% {
    transform: translate(20px, -3px);
  }

  100% {
    transform: translate(30px, 62px);
  }
}
