@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.native-select {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 4px 0;
  margin: 5px 0 10px;

  %label-focused {
    transform: translateY(-25px);
    font-size: rem(14);

    @include mq('desktop-s') {
      transform: translateY(-20px);
    }
  }

  .native-select__field {
    background-color: white;
    height: 40px;
    border: none;
    border-bottom: 1px solid lightgray;
    font-weight: 500;
    font-size: rem(22);
    color: $input-color;
    width: 100%;

    @include mq('desktop-s') {
      font-size: rem(16);
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      padding: 13px 20px;
      height: 48px;
    }
  }

  &.native-select--invalid {
    .native-select__field {
      border-bottom: 1px solid $danger-color;

      @include mq('desktop-s') {
        border-color: $danger-color;
      }
    }

    .label__name {
      color: $danger-color;
    }
  }

  &.native-select--tertiary {
    .native-select__label {
      color: $tertiary-color;
    }

    .Select-input {
      color: #fff;
    }
  }

  .native-select__label {
    background: $input-background-color;
    border-radius: $border-radius-default;
    display: flex;
    flex-direction: column;
    color: $input-placeholder-color;
    position: relative;

    .label__name {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 20px;
      font-size: rem(22);
      margin: auto;
      display: flex;
      align-items: center;
      transform: translateY(0);
      transition: 200ms;
      pointer-events: none;

      @include mq('desktop-s') {
        background: #fff;
        font-size: rem(16);
        left: 10px;
        padding: 0 13px;
      }
    }

    &.native-select__label--has-value {
      .label__name {
        @extend %label-focused;
      }
    }

    .is-focused + .label__name {
      @extend %label-focused;
    }
  }

  .native-select__error {
    font-size: rem(12);
    margin: 4px 0 0;
    color: $danger-color;
  }
}
