@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fade-in-overlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    z-index: $modal-overlay-z-index;
  }
}

@keyframes slide-in-from-left {
  from {
    transform: translateX(-50vw);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-right {
  from {
    transform: translateX(50vw);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up-context-card {
  from {
    transform: translateY($minimized-card);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes show-content {
  0% {
    transform: translateY($minimized-card);
  }

  50% {
    transform: translateY($minimized-card);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes show-content-maximized {
  0% {
    transform: translateY(400px);
  }

  10% {
    transform: translateY(400px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes placeholder-animate {
  0% {
    background-position: -650px 0;
  }

  100% {
    background-position: 650px 0;
  }
}

@keyframes fade-in-circ {
  from {
    opacity: 0;
    transform: rotate(60deg) scale(0.5);
  }

  to {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.89);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(color.adjust($primary-color, $lightness: 30%), 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(color.adjust($primary-color, $lightness: 30%), 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(color.adjust($primary-color, $lightness: 30%), 0);
  }
}

@keyframes shadow-scale {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.4);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes lp-pwa-moveclouds {
  0% {
    left: -150px;
  }

  100% {
    left: 100%;
  }
}

@keyframes lp-pwa-flying {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moving-fade-in-left {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moving-fade-in-right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes animate-progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
