@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-rules-modal {
  width: 100%;
  height: 560px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  color: $text-color-super-darken;

  @include mq('tablet') {
    width: 600px;
    height: 100%;
  }

  .marmita-modal__inner-content {
    background: white;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    border-radius: $border-radius-default;
    position: relative;
    padding: 0;

    @include mq('tablet') {
      height: 612px;
    }
  }

  &__overlay {
    background-color: color.adjust($text-color, $alpha: 0.28);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10001;
  }

  .marmita-modal__actions {
    position: relative;
  }

  &__container {
    position: relative;
    color: $text-color-super-darken;
    padding: 32px;
    display: grid;
    align-content: center;

    @include mq('tablet') {
      padding: 10px 70px;
      min-height: 612px;
    }
  }

  &__icon {
    width: 86px;
    height: 86px;
    margin: 0 auto 32px;
    display: block;

    .dash-icon-voucher {
      animation: animation-voucher 5s ease-in-out infinite;
    }

    @include mq('tablet') {
      width: 94px;
      height: 94px;
    }
  }

  &__title {
    font-size: rem(18);
    line-height: rem(22);
    font-weight: 500;
    text-align: center;
    margin: 0;
    padding: 0 0 8px;
    color: $text-color-super-darken;

    @include mq('tablet') {
      font-size: rem(24);
      line-height: rem(30);
    }
  }

  &__short-description {
    padding: 0 0 $space-pink;
    text-align: center;
    display: block;
    font-size: rem(14);
    line-height: rem(20);
    color: $text-color-super-darken;

    @include mq('tablet') {
      font-size: rem(16);
      line-height: rem(20);
    }
  }

  &__btn-see-merchants {
    width: 100%;
    display: grid;
    font-size: rem(14);
    line-height: rem(18);
    text-align: center;
    font-weight: 500;
    margin: 0 0 24px;
    padding: 0;
    text-decoration: unset;
    border-radius: $border-radius-default;
    color: #fff;

    @include mq('tablet') {
      padding: 16px 0;
      margin: 0 0 32px;
      font-size: rem(16);
      line-height: rem(20);
    }

    &:hover:enabled {
      color: #fff;
      background: color.adjust($button-primary-color, $lightness: 10%);
    }
  }

  &__title-rules {
    font-size: rem(16);
    line-height: rem(22);
    font-weight: 500;
    padding: 0 0 8px;
    display: block;
  }

  &__rules {
    list-style: disc;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 300;
    color: $text-color;
    white-space: pre-line;
  }
}

@keyframes animation-voucher {
  0% {
    transform: skew(-1deg);
  }

  50% {
    transform: skew(2deg);
  }

  100% {
    transform: skew(-1deg);
  }
}
