@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.content-container {
  padding: 0 $space-blue 0;

  // TO-DO: Change it to the new breakpoints
  @include mq('tablet') {
    padding: 0 $space-purple;
  }

  // Safe vertical spacing to avoid content overflow
  @include mq('desktop-s') {
    padding: $space-thin-pink $space-content-desktop-s;
  }

  @include mq('desktop') {
    padding: $space-thin-pink $space-content-desktop;
  }

  @include mq('desktop-wide') {
    padding: $space-thin-pink $space-content-desktop-wide;
  }

  @include mq('desktop-ultrawide') {
    padding: $space-thin-pink $space-content-desktop-ultrawide;
  }
}
