@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$quick-add-icon-size: 38px;

.quick-add {
  .marmita-counter {
    width: 100px;
    height: $quick-add-icon-size;
    border-radius: $border-radius-double;
    justify-content: flex-end;
    box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
    overflow: hidden;
    transition: all 200ms;
    background-color: $white-color;

    &--minimal {
      width: $quick-add-icon-size;
      border-radius: 50%;
    }

    .btn-icon {
      min-width: $quick-add-icon-size;
    }

    &__value {
      min-width: 24px;
      font-size: rem(16);
    }
  }
}
