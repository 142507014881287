@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.tooltip-ui-kit {
  display: inline-block;
  cursor: help;
  white-space: nowrap;

  &__container {
    position: absolute;
    z-index: $tooltip-z-index;
    min-width: 200px;
    max-width: 300px;
    background: white;
    box-shadow: 0 3px 16px rgb(0 0 0 / 15%);
    border-radius: $border-radius-default;
    padding: 5px;

    &--bottom,
    &--top,
    &--inner {
      transform: translateX(-33.33%);
    }

    &--left {
      transform: translateX(-100%);
    }
  }

  &__content {
    display: inline;
    padding: 10px;
  }
}
