@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-toggle {
  cursor: pointer;
  display: inline-block;
  height: 26px;
  position: relative;
  width: 44px;

  &__checkbox {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &:checked + .marmita-toggle__slider {
      background: $primary-color;

      &::before {
        background: #fff;
        transform: translateX(85%);
      }
    }
  }

  &__slider {
    background: $iron-color;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    transition: all 200ms linear;

    &::before {
      background: #fff;
      border-radius: 100%;
      bottom: 2px;
      content: '';
      height: 22px;
      left: 2px;
      position: absolute;
      transition: all 200ms linear;
      width: 22px;
    }

    &--disabled {
      opacity: 0.4;
    }
  }
}
