@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-dialog {
  padding: 0 10px 0 30px;

  @include mq('desktop-s') {
    height: 645px;
  }

  @include mq('tablet', max) {
    padding: 0;
  }

  .nav-header__title {
    color: $text-color-super-darken;
  }

  &-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-grey-color-100;
    padding: $space-blue 0;

    &__title {
      text-transform: uppercase;
      font-weight: 600;
      padding-left: 10px;
    }

    &__cta {
      height: 15px;
      width: 15px;
      border: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      transform: rotate(-90deg);

      .icon-marmita {
        height: 15px;
        width: 15px;

        path {
          fill: $button-primary-color !important;
        }
      }
    }
  }

  &-content {
    padding: 0 0 30px;
    overflow-y: auto;
    height: calc(645px - 62px);
    scrollbar-color: $light-grey-color-300 transparent;

    @include mq('tablet', max) {
      height: auto;
    }

    @include custom-scrollbar(6px, $light-grey-color-100);

    &__list {
      padding: 0 $padding-default;

      > * {
        margin-top: $space-blue;
      }

      .no-voucher {
        .voucher-slim-card {
          padding-left: $space-blue;
          padding-right: $space-blue;
          margin-top: $space-blue;
          height: 72px;

          &__title {
            color: $text-color;
            font-weight: 500;
          }

          &__short-description {
            color: $text-color-lighten;
          }

          &__icon {
            width: 28px;
            height: 28px;
          }
        }
      }

      .voucher-card {
        min-height: unset !important;

        > * {
          padding-left: $space-blue;
          padding-right: $space-blue;
        }

        &__header {
          align-items: center;

          .voucher-card__icon {
            width: 28px;
            height: 28px;
          }
        }

        &__cta-container {
          min-height: auto;
        }
      }
    }
  }
}
