@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.desktop-category-links {
  &__list {
    min-width: 360px;
    display: flex;
    flex-flow: row nowrap;

    &--floating {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: flex-start;
    }
  }

  &__item {
    a {
      height: 100%;
      font-family: $font-family-title;
      text-decoration: none;
      color: $text-color;
      font-size: rem(14);

      &:hover {
        color: $primary-color;
      }
    }

    & + & {
      margin-left: 16px;
    }

    &--active {
      a {
        color: $primary-color;
      }
    }
  }

  &__vertical-item {
    & + & {
      margin: 10px 0 0;
    }
  }
}
