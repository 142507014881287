@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.header-cart {
  display: flex;
  align-items: center;
  position: relative;
  padding: $space-thin-pink $space-thin-blue;
  border-radius: 200px;
  transition: background-color 300ms ease-in-out;
  border: 0;
  cursor: pointer;
  background-color: transparent;

  &:not(&--has-items) {
    &:hover,
    &:active {
      background-color: $light-grey-color-200;
    }

    &:active .header-cart__icon path {
      stroke: $brand-dark-color;
    }
  }

  &__icon {
    display: flex;
  }

  &__icon path {
    transition: stroke 300ms ease-in-out;
    stroke: $primary-color;
  }

  &__info {
    margin: 0 0 0 6px;
    color: $text-color;
    transition: background-color 300ms ease-in-out;
    white-space: nowrap;
    text-align: left;
  }

  &__cost {
    font-size: rem(12);
    line-height: rem(16);
    display: block;
  }

  &__items {
    font-size: rem(10);
    line-height: rem(12);
    display: block;
  }

  &--has-items {
    background-color: $primary-color;

    .header-cart {
      &__icon path {
        stroke: $white-color;
      }

      &__info {
        color: $white-color;
      }
    }
  }
}
