@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.bubble {
  width: 100%;
  background-color: $primary-color;
  color: #fff;
  height: 100%;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);

  &::after {
    content: '';
    position: absolute;
    background-color: $primary-color;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }

  &--arrow {
    &-top::after {
      top: -5px;
    }

    &-bottom::after {
      bottom: -5px;
    }

    &-right::after {
      right: 25px;
    }

    &-left::after {
      left: 25px;
    }
  }
}
