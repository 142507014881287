@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  margin: auto;

  &__title {
    font-size: rem(16);
    line-height: rem(18);
    font-weight: 400;
    margin-top: 16px;
    text-align: center;
    color: $text-color-super-darken;

    @include mq('desktop-s') {
      margin-top: 40px;
      font-weight: 500;
    }
  }

  &__description {
    font-size: rem(14);
    line-height: rem(18);
    font-weight: 300;
    text-align: center;
    color: $text-color;

    @include mq('desktop-s') {
      margin-top: 0;
    }
  }

  &__image,
  .marmita-lottie {
    width: 200px;
    height: 200px;

    @include mq('desktop-s') {
      width: 244px;
      height: 244px;
    }
  }

  .marmita-lottie {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
