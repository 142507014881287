@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.quick-online-vr-form {
  padding: 24px;

  &__checkbox-label {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 15px 0;
    width: 100%;
    cursor: pointer;
  }

  &__content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 24px;
    height: 18px;
  }

  &__text {
    margin-left: 18px;
    color: $text-color;
    font-size: 14px;
  }

  &__button-container {
    margin-top: 18px;
  }
}
