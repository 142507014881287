@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.icon-marmita {
  display: inline-block;
  position: relative;

  svg {
    color: $text-color;
    width: 100%;
    height: 100%;

    [stroke='#3F3E3E'] {
      stroke: currentcolor;
    }

    [fill='#3F3E3E'] {
      fill: currentcolor;
    }
  }

  .icon-youtube-play {
    fill: #fff;
  }

  .shadow-bg {
    opacity: 0;
  }
}
