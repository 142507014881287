@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-full-filter {
  $modal-height: 70vh;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__current-tab {
    padding: 0 20px;

    &-description {
      margin: 20px 0 10px;
      font-weight: 500;
    }
  }

  &__tabs {
    display: flex;
    border-bottom: solid 2px $light-grey-color;
    margin-bottom: 20px;

    &-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 15px 0;
      width: 100%;
      color: $text-color;

      &:nth-child(2) {
        margin: 0 8px;
      }

      &:hover,
      &--is-selected {
        color: $primary-color;
        border-bottom: solid 2px $primary-color;
        cursor: pointer;
      }
    }
  }

  &__modal {
    @include mq('tablet') {
      min-height: $modal-height;
      min-width: 40vw;
      max-width: 40vw;
    }
  }

  &__grid {
    display: grid;
    grid-template-areas:
      'header'
      'tab-container'
      'scroll'
      'action';
    grid-template-rows: 60px 60px minmax(auto, calc(var(--screen-y) - 170px)) 80px;

    @include mq('tablet') {
      grid-template-rows: 60px 60px minmax(auto, calc(#{$modal-height} - 170px)) 80px;
    }
  }

  &__tab-container {
    grid-area: tab-container;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    grid-area: header;

    &-title {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      color: $black-color;
      margin-left: 30px;
    }

    &-button {
      border: 0;
      background: transparent;

      @include mq('tablet') {
        cursor: pointer;
      }
    }

    &-icon {
      width: 25px;

      svg {
        color: $primary-color;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    outline: none;
    border: 0;
    background-color: transparent;
    color: $primary-color;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }
  }

  &__scroll {
    grid-area: scroll;
    overflow-y: auto;

    @include custom-scrollbar;
  }

  &__filter {
    grid-area: action;
    width: 100%;
    padding: 20px;
  }
}
