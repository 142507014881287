@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.mobile-category-selector-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #f7f7f7;

  &__categories {
    width: 100%;
    display: flex;
  }

  &__category {
    height: 26px;
    width: 100%;
  }
}
