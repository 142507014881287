@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.delivery-input {
  display: flex;
  flex-direction: row;
  appearance: none;
  border: none;
  padding: 0;
  height: 24px;
  cursor: pointer;
  background-color: unset;
  align-items: center;

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__icon-arrow {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: $space-thin-pink;

    @include colorize-icon($primary-color);
  }

  &__address-number {
    margin: 0 0 0 3px;
    flex-shrink: 0;
  }

  &__address {
    font-size: rem(14);
    color: $gray-color-700;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: left;
    line-break: anywhere;

    @include mq('desktop-s') {
      color: $text-color;
    }
  }
}
