@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-page {
  &__error {
    padding: $space-purple;
  }

  &__header {
    margin-bottom: $space-blue;
    padding: $space-blue 0;
    display: none;

    &--mobile {
      display: block;

      & ~ section {
        margin-top: $space-green;
      }
    }

    @include mq('desktop-s') {
      display: block;

      &--mobile {
        display: none;

        & ~ section {
          margin-top: unset;
        }
      }
    }
  }

  &__title {
    color: $text-color-super-darken;
    font-size: rem(25);
    margin-bottom: 0;
  }

  &__loading {
    height: calc(100vh - 200px);
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .cardstack-filter {
    top: 0;
    margin-top: 0;
  }

  @include mq('desktop-s') {
    .cardstack-filter {
      top: 72px;
    }
  }
}
