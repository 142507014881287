@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-minitag {
  display: inline-block;
  padding: 0 6px;
  background: #fff;
  border: 1px solid #e6e6e6;
  font-size: rem(12);
  border-radius: 3px;
  margin: 0 3px 0 0;
  line-height: 20px;
  height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;

  &--small {
    font-size: rem(9);
    line-height: 1;
    padding: 5px 5px 3px;
  }

  &--default {
    color: #4f4f4f;
  }

  &--green {
    color: $green-color;
  }

  &--primary {
    color: $primary-color;
  }

  &--disabled {
    color: $medium-grey-color;
  }

  &--black {
    background-color: $text-color;
    color: $light-grey-color;
    border: none;
    padding: 6px 6px 4px;
  }

  &--grayscale {
    background-color: $light-grey-color;
    color: $medium-grey-color;
  }
}
