@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.word-cloud {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin-top: 7px;
    margin-right: 7px;
  }
}
