@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.medium-banner-carousel-loading {
  border: 1px solid $light-grey-color-100;
  border-radius: $border-radius-default;
  width: 100%;
  height: 170px;

  @include mq('tablet') {
    width: 180px;
    height: 234px;
  }

  &__top {
    width: 100%;
    height: $home-button-height;
    margin-bottom: $margin-spacement-md;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, minmax($footer-height, 1fr));
    margin: 0;
    grid-gap: $margin-spacement-md;

    @include mq('tablet') {
      grid-template-columns: repeat(4, minmax(180px, 1fr));
      grid-gap: $space-blue;
    }

    @include mq('desktop-s') {
      grid-template-columns: repeat(6, 180px);
    }
  }

  &__title {
    margin-top: $space-thin-pink;
    height: $space-yellow;

    @include mq('tablet') {
      width: 180px;
    }
  }
}
