@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.full-tags {
  overflow-x: hidden;

  &__checkbox-label {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 15px 0;
    width: 100%;
    cursor: pointer;

    &:hover {
      .full-tags__text {
        color: $text-color-lighten;
      }
    }
  }

  &__content-wrapper {
    display: flex;
    justify-content: center;

    svg {
      width: 14px;
      height: 13px;
    }
  }

  &__img {
    width: 24px;
    height: 18px;
  }

  &__text {
    margin-left: 8px;
    color: $text-color;
    font-size: 14px;

    @include mq('desktop-s') {
      font-size: 16px;
    }
  }

  &__button-container {
    margin-top: 18px;
  }
}
