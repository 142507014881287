@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.manual-voucher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-grey-color-100;
  min-height: 90px;
  width: 100%;
  flex-wrap: wrap;
  padding: $padding-default;

  .input-wrapper {
    width: 70%;

    @include mq('tablet', max) {
      &--blurred {
        transition: 300ms;
        width: 100%;
      }
    }

    .form-input {
      border-radius: 4px;
      padding: 0;
      margin: 0;
      min-height: unset;

      @include mq('tablet', max) {
        background: $light-grey-color-100;

        &__label {
          padding: 0 0 0 16px;

          .label__name,
          .field__border {
            display: none;
          }
        }
      }

      &__field {
        @include mq('tablet', max) {
          font-weight: 500;
          font-size: em(14);
          padding: 0;
          border: 0;
          height: 44px;
          border-radius: 4px;
        }

        &::placeholder {
          color: $medium-grey-color;
          opacity: 1;
        }
      }
    }
  }

  &--error {
    color: $primary-color;
    text-align: center;
    display: block;
    min-height: 20px;

    span {
      font-weight: 500;
      text-transform: lowercase;
    }
  }

  &__button {
    width: 30%;
    font-size: em(14);
    font-weight: 400;

    @include mq('tablet', max) {
      &--hidden {
        display: none;
      }
    }
  }

  &__message {
    min-height: 20px;
  }
}

.manual-voucher--invalid {
  height: 110px;
}
