@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.simple-merchant-carousel-loading {
  min-width: 282px;
  border-radius: $border-radius-default;
  border: 1px solid $light-grey-color-100;
  display: grid;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  padding: 0 $margin-spacement-md;
  height: 76px;
  margin-right: $space-yellow;
  grid-template-columns: 48px 1px 1fr;
  grid-template-areas: 'figure divider content';
  grid-column-gap: $space-thin-blue;

  @include mq('desktop-s') {
    min-width: 363px;
    height: 126px;
    padding: 0 $space-blue;
    grid-template-columns: 85px 1px 1fr;
    grid-column-gap: $space-blue;
  }

  @include mq('desktop-s', max) {
    &::before {
      grid-area: divider;
      content: '';
      width: 1px;
      background-color: $light-grey-color-100;
      height: 100%;
    }
  }

  &__container {
    display: inline-flex;
    width: 100%;
  }

  &__figure {
    grid-area: figure;
    width: 48px;
    height: 48px;
    margin: auto;
    border-radius: 50%;

    @include mq('desktop-s') {
      width: 85px;
      height: 85px;
    }
  }

  &__content {
    grid-area: content;
    display: grid;
    align-items: center;
  }

  &__header {
    width: 95%;
    height: 20px;
    margin-bottom: $space-thin-yellow;
  }

  &__info {
    width: 65%;
    height: 18px;
  }
}
