@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.round-image-carousel-loading {
  display: flex;
  flex-direction: column;
  height: 114px;
  justify-content: space-between;

  @include mq('desktop-s') {
    margin: $space-purple 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    margin-right: $space-thin-blue;

    @include mq('desktop-s') {
      height: 112px;
      min-width: 262px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__item-image {
    width: 72px;
    height: 72px;
    margin-right: $space-thin-blue;
    border-radius: 50%;

    @include mq('desktop-s') {
      width: 262px;
      height: 112px;
      margin-right: $space-yellow;
    }

    &:first-child {
      margin: 0;
    }
  }

  &__item-text {
    height: 20px;
    margin-top: 4px;
  }
}
