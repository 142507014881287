@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-collapse {
  &--open {
    .marmita-collapse__title > .icon-marmita {
      transform: rotateX(180deg);
    }

    .marmita-collapse__content {
      animation: fade-in 300ms;
    }
  }

  &__button {
    background: none;
    border: 0;
    color: $black-color;
    font-weight: normal;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
    padding: 0;
  }

  &__title {
    align-items: center;
    display: flex;
    padding: 10px $padding-default;
    font-size: rem(18);
    position: relative;
    font-weight: bold;
  }

  .icon-marmita {
    @include colorize-icon($medium-grey-color);

    height: 25px;
    margin-left: 5px;
    transition: transform $animation-duration $ease-out-quad;
    width: 25px;
  }

  &__content {
    padding: 0 $padding-default;
    transition: 300ms $ease-out-circ;
  }
}
