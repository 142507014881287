@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.checkout-voucher {
  display: grid;
  grid-template-columns: 12% 80% 8%;
  align-items: center;
  height: 80px;
  margin: 0 20px;
  line-height: 16px;
  border-top: 1px solid color.adjust($color: $light-grey-color-300, $alpha: -0.5);
  border-bottom: 1px solid color.adjust($color: $light-grey-color-300, $alpha: -0.5);
  cursor: pointer;

  @include mq('desktop-s') {
    border-bottom: none;
    margin: 0 45px;
    grid-template-columns: 10% 80% 10%;
  }

  @media (min-height: 800px) {
    height: 80px;
  }

  &__voucher-icon {
    width: 34px;
    height: 34px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    padding: 15px;
  }

  &__title {
    font-size: rem(16);
    color: $text-color-super-darken;
    margin: 0 0 4px;
  }

  &__subtitle {
    margin: 0;
    font-size: rem(14);
    font-weight: 100;
    line-height: rem(20);
    color: $text-color;
  }

  &__icon-wrapper {
    position: relative;
    padding: 0 15px;

    .icon-marmita {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__chevron-icon {
    display: flex;
    width: 100%;
    height: 100%;
    stroke: $primary-color;
    stroke-width: 2px;
    justify-content: flex-end;
    align-items: center;

    svg {
      transform: rotate(90deg);
      width: 12px;
    }

    path {
      fill: $button-primary-color;
    }
  }

  &__animated-icon {
    position: absolute;
    opacity: 1;
    top: -22px;
  }
}
