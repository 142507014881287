@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-error-message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-align: center;

  & > * {
    max-width: 400px;
  }

  &__title {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  &__description {
    color: $medium-grey-color;
  }

  &__try-again {
    margin-top: 10px;
  }
}
