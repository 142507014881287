@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-badge {
  position: relative;
  font-weight: 500;
  padding: 2px;
  font-size: rem(14);
  line-height: 1;
  text-align: right;
  vertical-align: bottom;

  .icon-marmita {
    padding: 0 5px 0 0;
  }

  svg {
    transform: translateY(1px);
    width: 13px;
    height: 13px;
  }

  @include mq('tablet') {
    .marmita-badge__tooltip {
      z-index: 10;
      transform: translate(calc(-100% + 14px), 8px);
    }

    &:hover {
      .marmita-badge__tooltip {
        visibility: visible;
        opacity: 1;
        transition: 100ms ease-in-out;
      }
    }
  }

  &__tooltip {
    background: $light-grey-color-100;
    border-radius: 4px;
    position: absolute;
    width: 200px;
    display: block;
    color: $text-color;
    font-weight: 300;
    font-size: rem(12);
    line-height: rem(14);
    padding: $space-thin-pink;
    transform: translateY(0);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 100ms ease-in-out;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $light-grey-color-100;
      position: absolute;
      right: 4px;
      top: -5px;
    }
  }
}
