@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.mobile-category-selector {
  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  list-style: none;
  overflow-x: scroll;
  padding: $space-thin-pink 10px 0 10px;
  border-bottom: 1px solid $light-grey-color-200;

  &__tab {
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    a {
      display: block;
      padding: $space-thin-pink 10px;
      font-size: $space-yellow;
      font-weight: 500;
      color: $text-color;
      text-decoration: none;
    }

    &:first-child a {
      padding-right: 10px;
    }

    &--active {
      a {
        color: $primary-color;
      }
    }
  }

  &__underline {
    margin: 0 10px;
    border-bottom: 1px solid $primary-color;
    width: calc(100% - $space-blue);
  }
}
