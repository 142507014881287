@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.breadcrumbs {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &__label {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(18);
    color: $text-color;
    cursor: default;
    white-space: nowrap;

    &--link,
    a {
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $text-color-darken;
      }

      &:active {
        color: $text-color-super-darken;
      }
    }
  }

  &__icon {
    width: 28px;
    height: 28px;
    margin: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-marmita--arrow-down {
      width: 28px;
      height: 28px;
      transform: rotate(-90deg);
    }
  }
}
