@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.notification-permission {
  display: grid;
  min-width: 320px;
  grid-template-columns: 42px 1fr;
  padding: 12px 0 11px 24px;
  position: relative;
  border: 1px solid $light-grey-color-200;
  overflow: hidden;
  height: 110px;
  opacity: 1;
  transition: all 300ms ease-out;

  @include mq('desktop-s') {
    max-width: 345px;
  }

  &--gray {
    background-color: $light-grey-color-200;
  }

  &--hidden {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    opacity: 0.5;
  }

  &__illustration {
    overflow: visible;
    align-self: center;
  }

  &-center {
    display: grid;
    margin: 0 13px 0 15px;

    &__title {
      font-size: rem(14);
      margin-bottom: 10px;
      font-weight: 500;
      color: $text-color-super-darken;
    }

    &__subtitle {
      font-size: rem(12);
      margin-bottom: 12px;
      color: $text-color;
    }

    &__button {
      &.btn {
        height: 20px;
        margin-right: auto;
        padding: 0;
      }

      font-size: rem(14);
      color: $primary-color;
    }
  }

  &__close-button {
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;
    top: 10px;
    right: 10px;
    position: absolute;

    &.btn.btn--iconize {
      .icon-marmita {
        &.btn__icon {
          width: 15px;
          height: 15px;
        }

        svg {
          fill: $text-color-lighten;
          stroke: $text-color-lighten;
          stroke-width: 2;
        }
      }
    }
  }

  .phone-chat-ballon__ballon {
    animation: phone-chat-ballon-animation 2s infinite linear alternate;
    transform-origin: center;
  }

  .phone-chat-ballon__ifood {
    animation: phone-chat-ballon-ifood-animation 2s infinite linear alternate;
    transform-origin: center;
  }

  .phone-chat-ballon__alert {
    animation: phone-chat-ballon-alert-animation 2s infinite linear alternate;
    transform-origin: center;
  }
}

@keyframes phone-chat-ballon-ifood-animation {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-2px, -1px);
  }
}

@keyframes phone-chat-ballon-animation {
  0% {
    transform: translate(0, 0) rotate(0);
  }

  100% {
    transform: translate(-1px, -1px) rotate(-3deg);
  }
}

@keyframes phone-chat-ballon-alert-animation {
  0% {
    transform: translate(0, 0) rotate(0);
  }

  100% {
    transform: translate(-2px, -2px) rotate(0);
  }
}
