@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-quick-add {
  overflow: hidden;
  width: 48px;
  height: 48px;
  transition: all 200ms $ease-out-quad;
  border-radius: 50%;
  background-color: #fff;
  position: relative;

  &--expanded {
    width: 100px;
    border-radius: 8px;
  }

  &__button {
    display: inline-block;
  }

  .round-button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  &__counter {
    background-color: #fff;
    position: absolute;
    right: 0;
    width: 100px;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 50ms 200ms $ease-out-quad;

    &--opened {
      pointer-events: initial;
      opacity: 1;
      transition: opacity 200ms $ease-out-quad;
    }

    .marmita-counter {
      border: 0.5px solid $light-grey-color-100;
      bottom: 16px;
      width: 100px;
      justify-content: space-evenly;
      border-radius: 8px;
      box-shadow: 0 0 16px rgb(0 0 0 / 8%);

      .btn-icon {
        min-width: 38px;
      }

      &__value {
        min-width: 24px;
      }
    }
  }

  .icon-marmita.icon-marmita--plus-sign {
    width: 20px;
  }
}
