@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.option-button {
  &.btn.btn--default {
    width: 100%;
    height: 56px;
    margin: 0;
    border-radius: 8px;
    padding: 20px;
    border: solid 1px $light-grey-color-100;
    display: inline-flex;
    color: $text-color;
    background-color: transparent;
    text-transform: capitalize;
  }

  @include mq('desktop-s') {
    &.btn.btn--default {
      display: inline-flex;
      justify-content: space-between;
      min-width: 100px;
      height: 60px;
      color: $text-color;
      background-color: transparent;
      font-size: rem(16);
      font-weight: 400;
    }

    &__description-text {
      :nth-child(n + 2) {
        font-weight: 100;
        font-size: rem(13);
      }
    }
  }

  .icon-marmita {
    width: 20px;

    @include colorize-icon($green-color);

    animation: fade-in-circ 150ms $ease-out-quad;
  }

  &__icon {
    display: inline-flex;
    width: 24px;
    position: relative;
    top: -1px;
    margin-right: 14px;
  }

  &__description {
    @include truncate;

    text-align: left;
    flex-basis: 100%;
  }

  &__description-text {
    font-weight: 300;
    font-size: rem(14);
    line-height: em(20);
    color: $text-color-super-darken;

    // width forces truncate
    width: calc(100% - 0.1px);

    @include truncate;

    display: inline-flex;
    flex-flow: nowrap column;

    span {
      @include truncate;
    }
  }

  &__check {
    padding: 0 0 0 7px;
  }

  canvas {
    visibility: visible;
    color: color.adjust($green-color, $lightness: 30%);
  }

  &.btn.option-button--active,
  &.btn:hover {
    background-color: color.adjust($green-color, $lightness: 40%);
    border-color: transparent;
  }
}
