@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.full-grid {
  &__title {
    margin: 0 0 16px;
    color: $text-color-super-darken;
    font-size: rem(16);

    &--center {
      text-align: center;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    margin: 20px 0;

    @include mq('desktop-s') {
      grid-template-columns: initial;
      grid-row-gap: initial;
      grid-auto-flow: column;
    }
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    background: white;
    border: $footer-border-color solid 1px;
    border-radius: 50%;
    transition: all ease-out 0.2s;
  }

  &__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 85px;
    background: white;
    border: none;
    cursor: pointer;
    place-self: center;
    color: $text-color;
    align-self: flex-start;

    @include mq('desktop-s') {
      height: 150px;
    }

    &--active {
      color: $primary-color;

      .full-grid__img-container {
        background-color: $bubblegum;
        border-color: transparent;
      }

      .full-grid__description {
        color: $primary-color;
      }
    }

    &:hover {
      color: $primary-color;

      .full-grid__img-container {
        background-color: $bubblegum;
        border-color: transparent;
      }

      .full-grid__description {
        color: $primary-color;
      }
    }
  }

  &__description {
    font-size: rem(14);
    line-height: rem(14);
    color: $text-color;
    margin-top: 5px;
    transition: color ease-out 0.2s;

    @include mq('desktop-s') {
      font-size: rem(14);
    }
  }
}
