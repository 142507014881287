@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-image-expandable {
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    cursor: zoom-in;
  }

  &__modal {
    background-color: transparent;
    border: none;
    width: auto;
    max-width: 100vw;
    height: auto;
    max-height: 100vw;
    padding: 0;
  }

  &__overlay {
    background-color: rgb(0 0 0 / 90%);
  }

  &__image {
    width: 100%;
  }

  &__modal-image {
    max-width: 100vw;
    max-height: 100vh;
  }
}
