@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.error-state-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;

  &__image {
    display: block;
    width: rem(189);
    height: auto;

    @include mq('desktop-s') {
      margin-top: rem(24);
    }
  }

  &__title,
  &__text {
    padding: 0 $padding-default;
    text-align: center;
  }

  &__title {
    font-size: rem(20);
    margin-top: rem(24);
    margin-bottom: 0;
    color: $black-color;
  }

  &__text {
    margin-top: rem(16);
    color: $medium-grey-color;
    line-height: rem(22);
  }
}
