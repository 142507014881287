@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.full-slider {
  width: 100%;
  margin-bottom: 15px;

  &__description {
    padding-bottom: 12px;
  }

  &__description,
  &__values {
    font-size: rem(14);
    color: $text-color;
    font-weight: 300;
    display: block;
    margin: 0;
  }

  &__values {
    display: flex;
    justify-content: space-between;
  }
}
