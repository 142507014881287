@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.responsive-header {
  display: none;
  position: sticky;
  flex-direction: row;
  align-items: center;
  column-gap: $space-pink;
  width: 100vw;
  height: $header-height;
  background-color: $white-color;
  box-shadow: inset 0 -1px 0 #dcdcdc;
  top: 0;
  z-index: $header-z-index;
  padding: $space-blue $space-orange;

  &--static {
    position: relative;
  }

  @include mq('desktop-s') {
    display: flex;
  }

  &__logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 62px;

    .btn-icon {
      border-radius: 0;
      width: 62px;

      .icon-marmita {
        width: 100%;
        height: 100%;

        @include colorize-icon($primary-color);
      }
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: $space-thin-blue;
    align-items: center;
  }

  &__button {
    padding: 0;
    cursor: pointer;
    min-width: 24px;
    width: 24px;
    margin: 0;

    .icon-marmita {
      @include colorize-icon($primary-color);

      margin: 0;
    }
  }

  .floating-box__button:hover .icon-marmita {
    margin: 0;
  }

  .icon-marmita {
    width: 24px;
  }

  .search-input {
    max-width: 700px;
    margin: 0 auto;

    &__divisor {
      flex: 1;
    }
  }

  .delivery-input {
    max-width: 16.66%;

    @include mq('desktop') {
      max-width: 12.82%;
    }

    @media only screen and (min-width: 600px) {
      max-width: 14.64%;
    }

    @include mq('desktop-wide') {
      max-width: 15.83%;
    }

    @include mq('desktop-ultrawide') {
      max-width: 16.67%;
    }

    &__item {
      width: max-content;
    }
  }
}
