@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-container {
  @include page-container;

  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  grid-gap: 30px;
  padding: 20px 40px;

  @include mq('desktop-s') {
    padding: 20px 30px 20px 50px;
    grid-template-columns: repeat(3, minmax(266px, 380px));
  }
}

.voucher-card {
  background-color: white;
  width: 100%;
  padding: 0;
  border: 1px solid $light-grey-color-200;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  border-radius: $border-radius-default;
  word-break: break-word;

  @include mq('desktop-s') {
    display: inline-grid;
    grid-template:
      'header'
      'shortDescription'
      'restaurantButton'
      'infos'
      'rules'
      'footer';
    min-height: 190px;
    max-height: 384px;
  }

  &--disabled {
    color: $text-color;

    .voucher-card__title {
      color: $text-color-lighten;
    }
  }

  &__header {
    position: relative;
    display: grid;
    grid-area: header;
    grid-template: 1fr / 32px 1fr 24px;
    grid-column-gap: $space-yellow;
    padding: $space-blue;

    @include mq('desktop') {
      grid-template-columns: 50px 1fr;
      grid-auto-flow: column dense;
      grid-column-gap: 10px;
      padding: $space-pink $space-purple;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    @include mq('desktop') {
      width: 50px;
      height: 50px;
    }
  }

  &__title {
    font-size: rem(16);
    color: $text-color-super-darken;
    margin: 0;
    display: flex;
    align-items: center;

    @include mq('desktop') {
      font-size: rem(18);
    }
  }

  &__radio {
    display: flex;
    align-items: center;
  }

  &__short-description {
    display: grid;
    grid-area: shortDescription;
    padding: 0 $space-blue $space-blue;
    color: $text-color;
    font-size: rem(14);
    font-weight: 300;

    @include mq('desktop') {
      padding: 0 $space-purple $space-pink;
    }
  }

  &__infos {
    display: grid;
    grid-area: infos;
    grid-template: 1fr / 50% 50%;
    padding: 0 $space-blue $space-thin-blue;

    @include mq('desktop') {
      padding: 0 $space-purple $space-thin-blue;
    }
  }

  &__terms {
    background-color: #fff;
    color: $primary-color;
    font-size: rem(14);
    font-weight: 500;
    border: 0;
    text-align: left;
    padding: 0;
    cursor: pointer;
  }

  &__expiration-date {
    color: $text-color-lighten;
    font-size: rem(14);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__block {
    width: max-content;
    display: grid;
    grid-area: footer;
    padding: 10px $space-blue;
    text-align: center;
    background-color: $light-grey-color-200;
    color: $text-color;
    font-size: rem(12);

    @include mq('desktop') {
      padding: $space-blue $space-purple;
    }
  }

  &__cta-container {
    display: grid;
    grid-area: restaurantButton;
    min-height: 60px;
    padding: 0 20px $space-blue 20px;
  }

  &__restaurants-link {
    border: 1px solid $primary-color;
    height: 40px;
    background: transparent;
    color: $primary-color;
    width: 100%;

    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }
}

.voucher-footer {
  display: grid;
  grid-area: footer;
  padding: 10px $space-blue;
  text-align: center;
  background-color: $light-grey-color-200;
  color: $text-color;
  font-size: rem(14);

  @include mq('desktop') {
    min-height: 50px;
    padding: $space-blue $space-purple;
  }

  &__block {
    display: grid;
    align-items: center;
    grid-area: footer;
    padding: 10px $space-blue;
    background-color: $light-grey-color-200;
    color: $text-color;
    font-size: rem(14);
    grid-template: 1fr / 20px 1fr;

    @include mq('desktop') {
      padding: $space-blue $space-purple;
      min-height: 50px;
    }

    @include mq('tablet') {
      padding: 10px $space-purple;
    }
  }

  &__available {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-block {
    width: 12px;
    display: inline-flex;
  }

  &__text-block {
    display: inline-flex;
    align-items: center;
    font-size: em(12);
  }
}
