@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.search-autocomplete__option {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 20px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &-image {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  &:hover,
  &:active,
  &:focus-within {
    background: $light-grey-color-100;
  }

  .btn {
    color: $text-color;
    font-weight: initial;
    height: 40px;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:focus,
    &:hover,
    &:active {
      color: $text-color-darken;

      svg,
      path {
        fill: $text-color-darken;
        color: $text-color-darken;
      }
    }

    &-item-history {
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
      color: $text-color;

      &:focus,
      &:hover,
      &:active {
        color: $text-color-darken;

        svg,
        path {
          fill: $text-color-darken;
          color: $text-color-darken;
        }
      }
    }

    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-decoration: none;
      color: $text-color;

      &:focus,
      &:hover,
      &:active {
        color: $text-color-darken;

        svg,
        path {
          fill: $text-color-darken;
          color: $text-color-darken;
        }
      }
    }

    &-item__text {
      white-space: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    &-item__subtext {
      font-size: 12px;
    }

    &-icon {
      background: initial;
      width: 16px;
      height: 16px;
      margin-right: 1rem;
    }
  }
}
