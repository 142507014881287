@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.info-card {
  background: $light-grey-color-200;
  border-radius: 8px;
  border: 1px solid $light-grey-color-300;
  padding: 16px 34px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  &__message {
    color: $text-color;
    line-height: rem(20);
    font-size: rem(14);
    margin: 0;
  }
}
