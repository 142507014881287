@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.nav-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: sticky;
  top: 0;
  left: 0;
  min-height: $navheader-height;
  z-index: $header-z-index;

  &--primary {
    background: #fff;
    box-shadow: 0 -1px 4px rgb(0 0 0 / 10%);

    .nav-header__title {
      color: $text-color-super-darken;
    }

    .btn-icon {
      @include mq('desktop-s') {
        cursor: pointer;
      }

      .icon-marmita {
        @include colorize-icon($primary-color);
      }
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
  }

  &--transparent {
    background: transparent;

    .nav-header__title {
      color: $text-color;
    }

    .btn-icon {
      .icon-marmita {
        @include colorize-icon($primary-color);
      }
    }
  }

  &--tertiary {
    .nav-header__title {
      color: #fff;
    }

    .btn-icon {
      .icon-marmita {
        @include colorize-icon($tertiary-color);
      }
    }
  }

  &--with-padding {
    padding: 10px $padding-default;
  }

  &__title {
    font-size: rem(12);
    font-weight: 500;
    left: 0;
    margin: 0;
    padding: 2px 50px 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    z-index: 0;
  }

  &__subtitle {
    font-size: rem(15);
    font-weight: 300;
    text-transform: capitalize;
    color: $text-color-lighten;
  }

  .btn-icon {
    margin: 0;
    z-index: 1;
    border-radius: 0;
    height: 40px;
    width: 50px;
    left: -17px;
    padding: 0 0 0 14px;
  }

  &__left-button {
    justify-content: flex-start;
  }
}
