@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.payment-cash-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 $padding-default;

  &__title {
    font-size: rem(18);
    font-weight: 500;
    text-align: center;
  }

  &__message {
    display: flex;
    flex-direction: column;
    font-size: rem(14);
    font-weight: lighter;
    line-height: 18px;
    padding: 20px 40px 0;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__button {
    display: flex;
    flex: 1;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }

  &__input-container {
    font-size: rem(22);
    color: $medium-grey-color;
    font-weight: bold;
    display: flex;
    align-items: baseline;
    justify-content: center;

    .simple-form-input__field {
      width: 110px;
      text-align: center;
    }
  }

  &__currency {
    padding-right: 5px;
  }
}
