@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.require-cvv-modal {
  padding: $padding-default;

  &__title {
    color: $black-color;
    font-size: rem(20);
    font-weight: 500;
    margin: 0 0 10px;
    text-align: center;
  }

  &__description {
    color: $text-color;
    font-size: rem(16);
    font-weight: 300;
    line-height: 22px;
    margin: 0 0 10px;
    text-align: center;
  }

  &__payment {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;

    > img {
      max-width: 22px;
    }

    &-description {
      font-size: rem(14);
      color: $input-color;
      font-weight: 300;
    }
  }

  &__input-container {
    display: flex;
    align-items: baseline;
    justify-content: center;

    .form-input__field {
      text-align: center;
    }

    @media (max-width: 768px) {
      .simple-form-input {
        width: 100%;
      }
    }
  }
}
