@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.steps-router__step {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;

  &--invisible {
    z-index: 0;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.steps-router__step-transition--right {
  transform: translateX(0%);
  transition: transform ease 300ms;
}

.steps-router__step-transition--right-invisible {
  transform: translateX(100%);
  transition: transform 1ms ease 300ms;
}

.steps-router__step-transition--fade {
  transform: translateX(0%);
  transition: opacity ease 300ms;
  opacity: 1;
}

.steps-router__step-transition--fade-invisible {
  transform: translateX(100%);
  opacity: 0;
  transition: opacity 1ms ease 300ms 300ms, transform 300ms ease 300ms;
}

.steps-router__step-mount-transition--fade {
  animation: fade-in 300ms;
}
