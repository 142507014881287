@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.info-card-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  margin: auto;

  &__image {
    width: 200px;
    height: 200px;
  }

  &__title {
    margin: 40px 0 10px;
    height: 18px;
    width: 80%;
  }

  &__description {
    margin-bottom: 14px;
    height: 18px;
    width: 100%;
  }

  &__action {
    width: 160px;
    height: 50px;
  }
}
