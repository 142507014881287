@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-home {
  &__error {
    padding: $space-purple;
  }

  &__header {
    padding: $space-blue 0;
    display: none;

    &--mobile {
      display: block;
    }

    @include mq('desktop-s') {
      display: block;

      &--mobile {
        display: none;
      }
    }
  }

  &__title {
    color: $text-color-super-darken;
    font-size: rem(25);
    margin-bottom: 0;
  }

  &__loading > * {
    margin-top: $space-blue;
  }

  .cardstack-filter {
    top: 0;
    margin-top: 0;
  }

  @include mq('desktop-s') {
    .cardstack-filter {
      top: 72px;
    }
  }
}
