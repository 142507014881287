@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.pill-progress-bar {
  width: 100%;

  .pills {
    display: grid;
    grid-template-columns: repeat(1fr);
    grid-auto-flow: column dense;
    gap: 4px;
  }

  .pill {
    width: 100%;
    height: 4px;
    background: $light-grey-color-100;
    border-radius: 4px;
    position: relative;

    &.completed {
      > span {
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        background: linear-gradient(90deg, $sea-buckthorn 0%, $saffron 100%);
        border-radius: 4px;
      }
    }

    &.last-completed {
      > span {
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        background: linear-gradient(90deg, $sea-buckthorn 0%, $saffron 100%);
        border-radius: 4px;
        animation: animate-progressBar 1s ease-out 150ms backwards;
      }
    }
  }
}
