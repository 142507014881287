@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.banner-grid-loading {
  &.ph-loading {
    height: 60px;
    border-radius: 4px;

    @include mq('mobile-l') {
      height: 86px;
    }

    @include mq('desktop-s') {
      padding: 16px;
      height: 140px;
      border-radius: 8px;
    }
  }

  &__container {
    padding: 0 16px;

    @include mq('desktop-s') {
      @include page-container;

      padding: $padding-default-desktop;
    }
  }

  &__title {
    width: 120px;
    height: 20px;
  }

  &__wrapper {
    margin-top: 16px;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);

    @include mq('mobile-l') {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq('desktop-s') {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
    }
  }
}
