@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.header {
  height: 111px;
}

@keyframes animate {
  0% {
    top: -120px;
  }

  100% {
    top: 0;
  }
}

.home-header {
  z-index: 9;
  background: white;
  transition: top 0.3s ease-in-out;

  &--show {
    top: 0;
    position: sticky;
    animation: animate 0.25s ease-in-out;
  }

  &__container {
    background: $white-color;
    margin: auto;
    z-index: $header-z-index;
    border-top: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $light-grey-color-200;
    padding: $padding-default $padding-default $space-yellow 0;
    align-items: center;

    @include mq('tablet') {
      padding: 0;
    }
  }

  &__logo {
    cursor: pointer;

    .btn-icon {
      margin: 0 $space-pink 0 $space-blue;
      border-radius: 0;
      width: 50px;
      height: 26px;

      @include mq('tablet') {
        border-radius: 0;
        height: 64px;
        margin-right: $space-blue;
      }

      .icon-marmita {
        width: 100%;
        height: 100%;

        @include colorize-icon($primary-color);
      }
    }
  }

  .delivery-input__address {
    margin-left: 0;
  }

  .delivery-input {
    flex: 1;
  }

  .btn.btn--iconize {
    .btn__icon {
      border-radius: 0;
      width: 23px;
      height: 23px;
    }
  }

  .floating-box__button:hover .icon-marmita {
    margin: 0;
  }
}
