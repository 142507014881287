@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-filter {
  display: flex;
  list-style: none;
  background: $white-color;
  z-index: 2;
  width: calc(100% + $space-purple);
  overflow-x: scroll;
  padding: $space-yellow $space-blue 10px $space-blue;
  margin: #{-$space-blue} #{-$space-blue} 0 #{-$space-blue};

  &--sticky {
    position: sticky;
  }

  @include mq('desktop-s') {
    top: 56px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    white-space: nowrap;
    margin-right: rem(8);

    @include mq('tablet') {
      margin-right: rem($space-thin-blue);
    }
  }

  @include mq-between('desktop-s', 'desktop') {
    &__item {
      display: none;
    }

    &__item:nth-child(-n + 5) {
      display: inherit;
    }

    &__item:nth-last-child(-n + 2) {
      display: inherit;
    }
  }

  @include mq-between('desktop', 'desktop-wide') {
    &__item {
      display: none;
    }

    &__item:nth-child(-n + 6) {
      display: inherit;
    }

    &__item:nth-last-child(-n + 2) {
      display: inherit;
    }
  }
}
