@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.quick-online-vr {
  &__modal {
    @include mq('tablet') {
      min-width: 600px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    color: $text-color-super-darken;
    margin: 0;
    text-align: center;
  }
}
