@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.user-menu-items {
  min-width: 320px;
  max-height: calc(100vh - #{$header-height});
  overflow-y: auto;
  scrollbar-color: $light-grey-color-300 transparent;

  @include custom-scrollbar;

  @include mq('desktop-s') {
    padding: 0 0 40px;
  }

  &__name {
    font-weight: 500;
    font-size: rem(25);
    line-height: 31px;
    letter-spacing: -1px;
    padding: 30px 40px 20px;
  }

  &__notification {
    .notification-permission {
      margin: 0 auto;
    }

    @include mq-between('mobile', 'desktop-s') {
      padding: $space-thin-blue 20px;
    }
  }
}
