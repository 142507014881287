@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.simple-form-input {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 4px 0;
  margin: 5px 0;
  z-index: 0;
  min-height: 73px;

  %label-focused {
    transform: translateY(-23px);
    font-size: rem(14);
    background: #fff;
    z-index: 1;
    padding: 5px;
  }

  &__label {
    background: $input-background-color;
    display: flex;
    flex-direction: column;
    color: $input-placeholder-color;
    position: relative;
    padding: 0;

    .label__name {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 20px;
      margin: auto;
      display: flex;
      align-items: center;
      transform: translateY(0);
      transition: 200ms;
      font-size: rem(16);
      left: 14px;
    }

    &--has-value {
      .label__name {
        @extend %label-focused;
      }
    }
  }

  &__field {
    color: $input-color;
    background: transparent;
    font-weight: 500;
    outline: none;
    border-radius: $border-radius-default;
    border: 1px solid $input-border-bottom;
    position: relative;
    padding: 13px 20px;
    height: auto;
    z-index: 1;
    width: 100%;
    font-size: rem(16);
    line-height: 20px;

    &:focus + .label__name {
      @extend %label-focused;

      & + .field__border {
        width: 100%;
      }
    }

    &:-webkit-autofill {
      box-shadow: 0 30px 0 0 $input-background-color inset;
    }
  }

  &__field[disabled] {
    opacity: 0.4;
  }

  &__error {
    font-size: rem(12);
    margin: 4px 0 0;
    color: $primary-color-lighten;
  }

  &--invalid {
    .simple-form-input__field {
      border-color: $primary-color-lighten;
    }

    .label__name {
      color: $primary-color-lighten;
    }

    .field__border {
      background: $primary-color-lighten;
    }
  }
}
