@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-toastr-container {
  position: fixed;
  width: 100%;
  top: -50%;
  margin: auto;
  z-index: $toastr-z-index;
  transition: 400ms;

  &--success {
    background: $green-color;
  }

  &--error {
    background: rgb(0 0 0 / 90%);
  }

  // @include mq('desktop-s') {
  //   background: #717171;
  // }

  &#{&}--is-visible {
    top: 0;
    transition: 600ms;
  }

  .marmita-toastr {
    padding: 15px $margin-default;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include mq('desktop-s') {
      @include page-container;

      justify-content: flex-start;
      width: 100%;
    }

    @include mq('desktop-s', max) {
      height: 100%;
    }
  }

  .marmita-toastr__message {
    font-size: rem(16);
    color: #fff;
    flex-grow: 2;
    max-width: 80%;
    text-align: center;
    position: relative;
    top: 2px;
    min-height: 20px;

    @include mq('desktop-s') {
      display: flex;
      align-items: center;
      min-height: 80px;
      font-size: rem(20);
    }
  }

  .marmita-toastr__icon {
    position: absolute;
    left: 15px;
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
      fill: #fff;
    }

    @include mq('desktop-s') {
      position: relative;
      top: 0;
      width: auto;
      height: auto;
      margin-right: 30px;

      svg {
        fill: #fff;
        height: 26px;
        position: relative;
        top: 4px;
      }
    }
  }
}
