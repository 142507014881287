@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 55%);
  z-index: $modal-overlay-z-index;

  &--theme-white {
    background: #fff;
  }

  &--theme-primary {
    background: $primary-color;
  }

  &--theme-transparent {
    background: transparent;
  }

  &--fullpage-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    z-index: $modal-overlay-z-index;
  }

  &--fullscreen-with-overlay-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70%;
    bottom: 0;
    min-height: 100%;
    z-index: $modal-overlay-z-index;
    background-color: rgba(#000, 0.8);
  }

  &--dialog-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    z-index: $modal-overlay-z-index;
    background-color: rgb(0 0 0 / 40%);

    &--darken {
      background-color: rgb(0 0 0 / 80%);
    }
  }

  &--with-actions {
    @include mq('tablet') {
      padding-top: 40px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    @include mq('tablet') {
      position: absolute;
      right: 0;
      top: 0;
    }

    .btn-icon {
      margin: 0;
      cursor: pointer;
    }
  }

  &__content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;

    &--animation-fullpage {
      height: 100%;
      width: 100%;
    }

    &--animation-fullscreen-with-overlay {
      background-color: $light-grey-color-100;
      width: 100%;
      height: 100%;

      @include mq('desktop-s') {
        margin-top: 100px;
        border-radius: 10px 10px 0 0;
      }
    }

    &--animation-dialog {
      border-radius: $border-radius-default $border-radius-default 0 0;
      padding: $padding-default 0;

      @include mq('tablet') {
        border-radius: 4px;
      }
    }

    &--after-open-fullscreen-with-overlay {
      margin-top: 0;
      background: #fff;
      top: calc(50% + 20px) !important;

      @include mq('mobile') {
        margin-top: 40px;
        height: calc(100% - 40px);
      }

      @include mq('desktop-s') {
        height: calc(100% - 100px);
        top: calc(50% + 50px) !important;
      }
    }
  }

  &__inner-content {
    overflow-y: auto;
    height: 100%;
    width: 100%;

    &-scroll {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-color: $light-grey-color-300 transparent;
    }

    @include custom-scrollbar(6px, $light-grey-color-100);
  }
}

.ReactModalPortal .marmita-modal__overlay {
  transition: opacity 150ms ease-in-out;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.marmita-modal__overlay--animation-right {
  .marmita-modal__overlay {
    transition: opacity 150ms ease-in-out;
    opacity: 1;
  }

  .marmita-modal__content {
    opacity: 1;
    transform: translateX(100vw);
  }

  .marmita-modal__content--after-open {
    transform: translateX(0);
    transition: transform 300ms ease;
  }

  .marmita-modal__content--before-close {
    transform: translateX(100vw);
  }
}

.marmita-modal__overlay--animation-bottom {
  .marmita-modal__content {
    transform: translateY(100%);

    @include mq('tablet') {
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -40%);
      transition: 1000ms ease;
    }
  }

  .marmita-modal__content--after-open {
    transition: transform 300ms ease;
    transform: translateY(0);

    @include mq('tablet') {
      left: 50%;
      opacity: 1;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      &.dish-modal {
        overflow-y: hidden;
      }
    }
  }

  .marmita-modal__content--before-close {
    transform: translateY(100%);

    @include mq('tablet') {
      transform: translate(-50%, -40%);
    }
  }
}
