@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-filter-loading {
  display: flex;
  height: 32px;
  justify-content: space-between;
  top: 60px;
  margin-top: -10px;
  margin-bottom: 10px;

  @include mq('desktop-s') {
    margin-top: -25px;
    margin-bottom: 50px;
  }

  &__item {
    height: 32px;
    margin-left: 12px;
    border-radius: 20px;
    flex-grow: 1;

    &:first-child {
      margin: 0;
    }

    @include mq('tablet') {
      height: 40px;
    }
  }
}
