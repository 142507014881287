@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.app-scroll-lock {
  position: relative !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  height: 100vh !important;
  width: 100vw !important;
}

.app-body-scroll-lock {
  position: fixed !important;
  overscroll-behavior-y: none !important;
}
