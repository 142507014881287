// ----------------------- //
// COLORS
// ----------------------- //
$primary-color: #ea1d2c !default;
$primary-dark-color: #c41835 !default;
$secondary-color: #f2d298 !default;
$cream-color: #f5e9da !default;
$tertiary-color: #fed298 !default;
$green-color: #50a773 !default;
$green-color-light: #1ea664 !default;
$orange-color: #e7a74e !default;
$light-orange-color: #fed298 !default;
$gray-color: #272727 !default;
$dark-black-color: #414143 !default;
$black-color: #3f3e3e !default;
$medium-grey-color: #a6a5a5 !default;
$light-grey-color: #f5f0eb !default;
$light-grey-color-100: #f2f2f2 !default;
$light-grey-color-200: #f7f7f7 !default;
$facebook-color: #4065b4 !default;
$light-grey-color-300: #dcdcdc !default;
$twitter-color: #1dcaff !default;
$youtube-color: #cc181e !default;
$danger-color: #b42b2b !default;
$super-color: #e0040b !default;
$almost-white-color: #f6f5f5 !default;
$iron-color: #d3d2d2 !default;
$rouge-color: #922029 !default;
$mostard-color: #e7a74e !default;
$masala-color: #595856;
$promotion-tag-color: #2e6788 !default;
$bubblegum: #fcebea !default;
$promotion-tag-color-light: #eef3f5 !default;
$sea-buckthorn: #f5a930;
$saffron: #f8bc3a;
$yellow-raincoat: #ffbe00;
$gray-color-700: #1a1a1a;
$neutral-gray-darker: #a6a6a5;
$brand-light-color: #ff4e4e;
$brand-dark-color: #b71420;
$white-color: #fff !default;

// Buttons
$button-primary-color: $primary-color !default;
$button-secondary-color: $cream-color !default;

// Lighten / darken
$primary-color-lighten: color.adjust($primary-color, $lightness: 10%) !default;
$primary-color-darken: color.adjust($primary-color, $lightness: 10%) !default;
$secondary-color-lighten: color.adjust($secondary-color, $lightness: 10%) !default;
$medium-grey-color-lighten: #dbdad9;
$gray-color-lighten: #a6a29f !default;
$primary-color-lighten: #ef5753 !default;
$bubblegum-darken: color.adjust($bubblegum, $lightness: 10%) !default;
$warning-color-lighten: #fff6d3 !default;
$warning-color-medium: #fab125 !default;
$warning-color-dark: #986400 !default;

// Spaces / spacings
$space-thin-yellow: 4px !default;
$space-thin-pink: 8px !default;
$space-thin-blue: 12px !default;
$space-yellow: 16px !default;
$space-blue: 20px !default;
$space-pink: 24px !default;
$space-acqua: 28px !default;
$space-orange: 32px !default;
$space-purple: 40px !default;
$space-atlantis: 48px !default;
$space-green: 64px !default;
$space-content-desktop-s: 80px !default;
$space-content-desktop: 120px !default;
$space-content-desktop-wide: 160px !default;
$space-content-desktop-ultrawide: 200px !default;

// Text
$text-color: #717171 !default;
$text-color-darken: #3f3e3e !default;
$text-color-super-darken: #3e3e3e !default;
$text-color-lighten: #a6a6a6 !default;
$text-color-super-lighten: rgba($text-color, 0.1) !default;

// ----------------------- //
// TYPOGRAPHY
// ----------------------- //
$font-family: 'iFood RC Textos', helvetica, sans-serif !default;
$font-family-title: 'iFood RC Titulos', 'iFood RC Textos', helvetica, sans-serif !default;
$body-font-size: 16 !default;
$font-size-default: $body-font-size + 0 !default;
$font-size-small-number: 15;
$font-size-small: $font-size-small-number + 0 !default;

// ----------------------- //
// BORDERS
// ----------------------- //
$border-radius-default: 4px !default;
$border-radius-double: 8px !default;
$border-radius-rounded: 25px !default;
$home-section-spacer: 10px solid $light-grey-color-100;

// ----------------------- //
// HEADER
// ----------------------- //
$header-height: 80px;
$header-height-greeting: 60px !default;

// ----------------------- //
// FOOTER
// ----------------------- //
$footer-height: 100px !default;
$footer-background-color: $gray-color !default;
$footer-border-color: #dcdcdc !default;

// ----------------------- //
// NAVBAR
// ----------------------- //
$navbar-background-color: rgb(255 255 255) !default;
$navheader-height: 60px;
$jobs-navheader-height: 86px;

// ----------------------- //
// FORM
// ----------------------- //
$input-background-color: transparent !default;
$input-placeholder-color: #a6a6a6 !default;
$input-color: #3e3e3e !default;
$input-border-bottom: #dcdcdc !default;
$input-placeholder-color-grey: #cdcbca !default;
$radio-size: 24px !default;
$radio-size-small: 20px !default;
$search-placeholder-home: #a6a6a5 !default;

// ----------------------- //
// ANIMATIONS
// ----------------------- //
$animation-duration: 0.3s !default;
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94) !default;
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1) !default;
$falu-red: #771212;
$ligth-pink: #c599c6;
$ziggurat: #aa85ab;
$carissma: #f18398;
$london-hue: #aa84ab;
$crimson: #e4002b;
$charm: #d2687c;
$blush: #b75c6d;

// ----------------------- //
// Z Indexes
// ----------------------- //
$toastr-z-index: 10021 !default;
$modal-overlay-z-index: 10001 !default;
$hint-z-index: 9999 !default;
$context-card-z-index: 9998 !default;
$tab-bar-z-index: 10000 !default;
$header-z-index: 9997 !default;
$voucher-header-z-index: 9993 !default;
$voucher-bg-z-index: 9992 !default;
$context-card-minimized-z-index: 9994 !default;
$overlay-z-index: 9993 !default;
$fullpage-select-searcher-z-index: 10010 !default;
$payment-action-z-index: 9990 !default;
$follower-order-desktop-card: 9990 !default;
$checkout-container-layer-1: 1;
$checkout-container-layer-2: 2;
$floating-box-z-index: 9994 !default;
$restaurant-menu-z-index: 9994 !default;
$user-profile-z-index: 9997 !default;
$splash-z-index: 9999 !default;
$floating-notification-z-index: 9990 !default;
$fixed-side-cart-z-index: 9993 !default;
$drawer-z-index: 9994 !default;
$tooltip-z-index: 999999 !default;
$product-detail-modal-z-index: 10000 !default;
$menu-group-list-modal-z-index: 9999 !default;
$catalog-search-mobile-modal-z-index: 9998 !default;
$merchant-page-search-input-active-z-index: 9997;
$cart-shuffle-modal-z-index: 100001 !default;

/* CHAT Z-INDEXES */
$inbox-fab-z-index: 9998 !default;
$inbox-floating-z-index: 9998 !default;
$inbox-modal-z-index: 9998 !default;
$inbox-header-z-index: 9998 !default;
$channel-floating-z-index: 9998 !default;
$channel-modal-z-index: 9998 !default;

// ----------------------- //
// Restaurant
// ----------------------- //

// Restaurant z-Indexes
$restaurant-dish-overlay: $product-detail-modal-z-index;
$address-select-overlay: 10001 !default;
$not-delivery-address-select-overlay: 10002 !default;
$garnish-choice-sticky-z-index: 20 !default;
$dish-action-buttons-z-index: 30 !default;
$dish-image-blur-z-index: 10 !default;
$dish-image-z-index: $dish-image-blur-z-index + 1 !default;
$address-invalid-overlay: $restaurant-dish-overlay + 1;

// Dish List
$dish-column-size: 160px;

// ----------------------- //
// Margins
// ----------------------- //
$margin-default: 20px !default;
$padding-default: 20px !default;
$padding-default-desktop: 30px !default;
$margin-spacement-md: 12px !default;

// ----------------------- //
// Ratings
// ----------------------- //

$default-rating-color: #fcbb00 !default;
$no-rating-color: #c4c4c4 !default;
$percent-bg-color: #deffed !default;
$percent-color: #0a7943 !default;

// ----------------------- //
// Context Card
// ----------------------- //
$minimized-card: 70px !default;
$cart-width: 430px !default;

// ----------------------- //
// Containers
// ----------------------- //
$desktop-container: 1366px !important;
$desktop-container-ultrawide: 1920px !important;
$new-lading-desktop-container: 1278px !important;

// ----------------------- //
// Tab bar
// ----------------------- //
$tab-bar-height: 50px;
$home-button-height: 20px;

// ----------------------- //
// Checkout
// ----------------------- //
$checkout-container-layer-1: 1;
$checkout-container-layer-2: 2;

// ----------------------- //
// CART
// ----------------------- //
$restaurant-cart-footer-fixed-height: 100px;
$cart-footer-height: 120px;

// ----------------------- //
// Cardstack
// ----------------------- //
$cards-box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
