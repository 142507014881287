@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.big-banner-carousel-loading {
  display: grid;
  grid-gap: 12px;
  margin-bottom: 20px;

  @include mq('mobile-s') {
    grid-template-columns: repeat(1, minmax(136px, 1fr));
  }

  @include mq('mobile-l') {
    grid-template-columns: repeat(2, minmax(136px, 1fr));
  }

  @include mq('desktop-s') {
    grid-template-columns: repeat(3, minmax(136px, 1fr));
  }

  &__item {
    @include mq('mobile-s') {
      height: 120px;
    }

    @include mq('mobile') {
      height: 144px;
    }

    @include mq('desktop-s') {
      height: 184px;
    }
  }
}
