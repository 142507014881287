@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$restaurant-cart-footer-fixed-height: 100px;

.restaurant-cart-footer {
  &__info {
    border-top: 1px solid $light-grey-color-300;
    margin: 0 45px $restaurant-cart-footer-fixed-height 45px;
    padding: $padding-default 0;

    > * {
      height: 32px;
      line-height: 32px;
      font-weight: 300;
      align-items: center;

      @include mq('desktop-s') {
        height: 28px;
      }

      @media (min-height: 800px) {
        height: 32px;
      }
    }
  }

  &__info-description {
    color: $text-color;
    font-size: rem(14);
  }

  &__green-text {
    color: $green-color;
  }

  &__delivery-fee-info {
    width: 20px;
    height: 20px;
    border-radius: 100%;

    .btn__label {
      top: 2px;
    }

    &::before {
      content: '';
      height: 17px;
      width: 17px;
      border-radius: 100%;
      display: block;
      background: transparent;
      position: absolute;
      border: 1px solid $medium-grey-color;
    }
  }

  &__total {
    font-weight: 500;

    .restaurant-cart-footer__info-description {
      color: $black-color;
      font-size: rem(18);
    }
  }

  &__loading {
    text-align: center;
    margin: 20px 0;
  }

  &__fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 45px;
    min-height: $restaurant-cart-footer-fixed-height;
    background-color: $white-color;
  }

  &__button-wrapper {
    margin: 10px 0 0;
  }
}

.restaurant-cart-footer__button--hide {
  .restaurant-cart-footer__info {
    margin: 0 45px;
    padding: 20px 0;
  }
}
