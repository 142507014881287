@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.low-stock-tag {
  display: inline-flex;
  background-color: $bubblegum;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;
  height: 19px;

  &__icon {
    margin: 0 5px;
  }

  &__text {
    margin: 0;
    color: $brand-light-color;
    font-size: rem(10);
    margin-right: 6px;
    font-weight: 500;
    line-height: 0;
  }
}
