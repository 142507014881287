@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.default-sorting {
  &__title {
    margin: 0 0 16px;
    color: $text-color-super-darken;
    font-size: rem(16);

    &--center {
      text-align: center;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    background: white;
    border: $footer-border-color solid 1px;
    border-radius: 50%;
    margin-bottom: $space-thin-blue;
    transition: all ease-out 0.2s;
  }

  &__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 72px;
    background: white;
    border: none;
    cursor: pointer;
    place-self: center;
    color: $text-color;

    &--active {
      color: $primary-color;

      .default-sorting__img-container {
        background-color: $bubblegum;
        border-color: transparent;
      }

      .default-sorting__description {
        color: $primary-color;
      }
    }

    &:hover {
      color: $primary-color;

      .default-sorting__img-container {
        background-color: $bubblegum;
        border-color: transparent;
      }

      .default-sorting__description {
        color: $primary-color;
      }
    }
  }

  &__description {
    font-size: rem(12);
    line-height: rem(14);
    color: $text-color;
    transition: color ease-out 0.2s;
  }
}
