@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.search-autocomplete {
  position: absolute;
  background: #fff;
  border: 1px solid $light-grey-color-200;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  border-radius: 4px;
  left: 17px;
  right: 9px;

  @include mq('desktop-s', max) {
    top: 70px;
  }

  &__title {
    font-size: 16px;
    margin: 0 0 10px;
    padding: 20px 20px 0;
  }

  &__text {
    color: $text-color;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus,
    &:hover,
    &:active {
      color: $text-color-darken;
    }
  }
}
