@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.payment-method-card {
  padding: 12px;
  border: 1px solid #dcdcdc;
  color: #3e3e3e;
  height: 128px;
  width: 128px;
  border-radius: 8px;
  cursor: pointer;

  @include mq('desktop-s') {
    height: 140px;
    width: 240px;
  }

  &--selected {
    border: 1px solid #ea1d2c;
  }

  &__link {
    text-decoration: none;
    color: #3e3e3e;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 48px;
  }

  &__icon {
    padding-right: 8px;
    display: flex;
    align-items: center;
    height: 24px;
    width: 28px;
  }

  &__name {
    font-size: rem(12);
    line-height: rem(16);
    padding-bottom: 2px;

    @include mq('desktop-s') {
      font-size: rem(14);
    }
  }

  &__title {
    font-size: rem(12);
    line-height: rem(16);

    @include mq('desktop-s') {
      font-size: rem(14);
      line-height: rem(18);
    }
  }

  &__description {
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 500;

    @include mq('desktop-s') {
      font-size: rem(18);
      line-height: rem(22);
    }
  }
}
