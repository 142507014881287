@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-cart-item {
  font-size: rem(16);

  &__description {
    margin: 0.5em 0;
    line-height: 1.4;
    gap: 50px;

    :last-child {
      font-weight: 500;
    }
  }

  &__garnish,
  &__obs {
    font-size: rem(14);
    font-weight: 300;
    color: $text-color;
    margin: 0.5em 0;
    line-height: 1.3;
    word-wrap: break-word; // support for older browsers
    overflow-wrap: break-word;
  }

  &__garnish {
    & > span:not(:first-child) {
      margin-left: 5px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .quick-add {
      margin-left: auto;

      .marmita-counter {
        box-shadow: none;
        border: 0.5px solid $light-grey-color-100;
        height: 48px;

        &__value {
          font-size: rem(18);
        }
      }
    }
  }

  &__button {
    height: 100%;
    font-size: rem(14);
    padding: 0;
    width: 70px;
    margin-right: 10px;

    .btn__label {
      text-align: left;
      font-size: rem(14);
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__tag {
    margin-right: 4px;
    display: flex;
  }

  .restaurant-cart-item-price--scale {
    display: block;
  }
}
