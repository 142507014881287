@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-list-loading {
  display: grid;
  transition: 200ms;
  height: 84px;
  border-radius: 4px;
  text-decoration: none;
  width: 100%;
  grid-template-columns: 56px 1fr;
  grid-template-areas: 'figure content';
  grid-column-gap: 12px;

  @include mq('desktop-s') {
    height: 102px;
    grid-template-columns: 85px 1fr;
    grid-column-gap: 20px;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: $space-thin-blue;

    @include mq('mobile-l') {
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    @include mq('desktop-s') {
      gap: $space-orange;
    }
  }

  &__figure {
    grid-area: figure;
    border-radius: 50%;
    width: 56px;
    height: 56px;

    @include mq('desktop-s') {
      width: 85px;
      height: 85px;
    }
  }

  &__content {
    grid-area: content;
    display: grid;
    grid-template-rows: 1fr 20px;
  }

  &__header {
    width: 85%;
    height: 18px;
    margin-bottom: 6px;
  }

  &__info {
    width: 65%;
    height: 14px;
  }

  &__footer {
    width: 45%;
    height: 14px;
  }
}
