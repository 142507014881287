@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.assist-modal {
  display: flex;
  align-items: center;
  background: #fff;

  &__wrapper {
    position: relative;
    padding: 10px $padding-default 80px;
    width: 100%;

    @include mq('desktop-s') {
      @include page-container;

      padding-top: $padding-default;
      padding-bottom: 120px;
    }
  }

  &__other-methods {
    padding: 16px 0 40px;

    @include mq('desktop-s') {
      padding: 24px 0 48px;
    }
  }

  &__error-description {
    font-size: rem(18);
    font-weight: 500;
    color: $text-color-super-darken;
    padding-top: 20px;
    margin: 0;

    @include mq('desktop-s') {
      font-size: rem(24);
      padding-top: 30px;
    }
  }

  &__change-payment-method {
    font-size: rem(14);
    color: $text-color;

    @include mq('desktop-s') {
      font-size: rem(16);
    }
  }

  &__payment-used {
    font-size: rem(16);
    color: $text-color-super-darken;

    @include mq('desktop-s') {
      font-size: rem(20);
      padding: 20px 0 0;
    }
  }

  &__text {
    font-size: rem(16);
    font-weight: 400;
    color: $text-color-super-darken;
    padding: 10px 0;
    margin: 0;

    @include mq('desktop-s') {
      font-size: rem(18);
      padding: 20px 0 0;
    }
  }

  &__message {
    margin: 5px 0;
    border-bottom: 1px solid;
    color: $input-border-bottom;
    padding: 12px 0;

    @include mq('desktop-s') {
      margin: 12px 0;
    }
  }

  &__title {
    font-size: rem(14);
    font-weight: 300;
    color: $text-color;
    padding: 5px 0;
    margin: 0;

    @include mq('desktop-s') {
      font-size: rem(18);
      font-weight: 300;
    }
  }

  &__description {
    font-size: rem(12);
    font-weight: 300;
    color: $text-color-super-darken;
    padding: 5px 0;
    margin: 0;

    @include mq('desktop-s') {
      font-size: rem(16);
      font-weight: 300;
    }
  }

  &__payment {
    margin: 0 0 15px;
    padding: 25px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;

    &-content {
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 10px;
      align-items: center;

      &--brand {
        width: 30px;
      }
    }

    &-description {
      display: grid;
      grid-template-rows: auto;
      gap: 5px;

      &--card {
        color: $text-color-super-darken;
      }

      &--number {
        color: $text-color;
      }
    }
  }

  &__retry-payment {
    border-top: 1px solid #f5f0eb;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9990;
    animation: fade-in $animation-duration;

    @include mq('desktop-s') {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      bottom: 40px;
    }

    &--wrapper {
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 20px;

      @include page-container;
    }
  }

  .carousel__controller {
    &--left {
      transform: translateY(-32px) rotateZ(90deg);

      @include mq('tablet') {
        transform: translateY(-18px) rotateZ(90deg);
      }

      @include mq('desktop-s') {
        left: -25px;
      }
    }

    &--right {
      transform: translateY(-32px) rotateZ(-90deg);

      @include mq('tablet') {
        transform: translateY(-18px) rotateZ(-90deg);
      }

      @include mq('desktop-s') {
        right: -25px;
      }
    }
  }
}
