@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.round-button {
  align-items: stretch;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #f2f2f2;
  box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
  box-sizing: border-box;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  width: 48px;
  position: relative;
  padding: 0;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 2px 16px rgb(0 0 0 / 16%);
  }

  .icon-marmita {
    pointer-events: none;
    display: inline-flex;
  }

  @include mq('desktop-s') {
    cursor: pointer;
  }
}
