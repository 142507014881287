@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.form-input {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 4px 0;
  margin: 5px 0;
  z-index: 0;
  min-height: 73px;

  %label-focused {
    transform: translateY(-20px);
    font-size: rem(16);

    @include mq('desktop-s') {
      font-size: rem(14);
      transform: translateY(-23px);
      background: #fff;
      z-index: 1;
      padding: 5px;
    }
  }

  .form-input__label {
    background: $input-background-color;
    padding: 15px 0 0;
    display: flex;
    flex-direction: column;
    color: $input-placeholder-color;
    position: relative;

    @include mq('desktop-s') {
      padding: 0;
    }

    .label__name {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 20px;
      margin: auto;
      display: flex;
      align-items: center;
      transform: translateY(7px);
      transition: 200ms;
      font-size: rem(22);

      @include mq('desktop-s') {
        font-size: rem(16);
        transform: translateY(0);
        left: 21px;
      }
    }

    .field__border {
      transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
      height: 2px;
      width: 0;
      margin: auto;
      display: block;
      background: $input-border-bottom;
      position: relative;
      top: -2px;
      z-index: 1;

      @include mq('desktop-s') {
        display: none;
      }
    }

    &.form-input__label--has-value {
      .label__name {
        @extend %label-focused;
      }
    }
  }

  .form-input__field {
    border: none;
    color: $input-color;
    background: transparent;
    height: 40px;
    font-weight: 500;
    outline: none;
    border-bottom: 1px solid $input-border-bottom;
    position: relative;
    z-index: 1;
    width: 100%;
    font-size: rem(22);

    @include mq('desktop-s') {
      font-size: rem(16);
      border-radius: $border-radius-default;
      border: 1px solid $input-border-bottom;
      padding: 13px 20px;
      height: auto;
      line-height: 20px;
    }

    &:focus + .label__name {
      @extend %label-focused;

      & + .field__border {
        width: 100%;
      }
    }

    &:-webkit-autofill {
      box-shadow: 0 30px 0 0 $input-background-color inset;
    }
  }

  .form-input__field[disabled] {
    opacity: 0.4;
  }

  .form-input__error {
    font-size: rem(12);
    margin: 4px 0 0;
    color: $danger-color;
  }

  &.form-input--invalid {
    .form-input__field {
      border-color: $danger-color;

      // color: $danger-color;
    }

    .field__border {
      background: $danger-color;
    }
  }

  &.form-input--tertiary {
    .form-input__field {
      border-color: $tertiary-color;
      color: #fff;
    }

    .field__border {
      background: $tertiary-color;
    }

    .form-input__label {
      color: $tertiary-color;
    }

    .form-input__error {
      color: #fff;
    }

    &.form-input--invalid {
      .form-input__field {
        border-color: #fff;
      }

      .field__border {
        background: #fff;
      }
    }
  }

  &__help-text {
    color: #717171;
    font-size: 11px;
    margin-top: 5px;
  }
}
