@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-dropdown-menu {
  display: flex;
  position: relative;
  background-color: $navbar-background-color;
  width: 100%;

  &__list {
    position: absolute;
    z-index: $floating-box-z-index;
    background-color: $navbar-background-color;
    top: 100%;
    width: 100%;
    min-width: 255px;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    max-height: calc(6.5 * 56px);
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: 200ms $ease-out-quad;
    right: 0;
  }

  &__list--visible {
    transition: 200ms $ease-out-quad;
    visibility: visible;
    opacity: 1;
  }

  &__item {
    position: relative;

    &:not(:first-child) {
      @include thin-border-before;
    }

    .btn {
      width: 100%;

      .btn__label {
        text-align: left;
        color: $black-color;
        font-weight: lighter;
        font-size: 1em;
      }
    }
  }

  &__item--alt {
    position: relative;

    .btn {
      @include thin-border-before($left: 10px, $padding: 30px);

      height: 60px;
      width: 100%;
      padding: 0 10px;
      margin: 0;

      .btn__label {
        text-align: left;
        font-size: rem(16);
        color: $medium-grey-color;
        line-height: 19px;
        font-weight: 400;
      }

      .btn__icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;

        svg {
          color: $medium-grey-color;
        }
      }

      &:hover {
        .btn__label {
          color: #595756;
        }
      }
    }
  }

  &__item--active {
    .btn {
      .btn__label {
        color: #595756;
      }
    }
  }

  &__item--title {
    .btn {
      padding: 18px 0 10px 14px;
      margin: 0;
      cursor: default;
      pointer-events: none;

      .btn__label {
        text-align: left;
        font-size: rem(18);
        color: $black-color;
        line-height: 22px;
        font-weight: 500;
      }
    }
  }

  &__button {
    display: flex;
    width: 100%;

    .btn {
      width: 100%;
      padding: 0 0 0 30px;

      .btn__label {
        text-align: left;
        color: $black-color;
      }
    }

    .btn.btn--iconize .btn__icon {
      @include colorize-icon($black-color);
    }
  }
}
