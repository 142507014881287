@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-section {
  & + & {
    margin-top: 20px;

    @include mq('mobile-l') {
      margin-top: 40px;
    }

    @include mq('desktop-s') {
      margin-top: 60px;
    }
  }
}
