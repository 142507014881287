@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-image {
  &--responsive {
    width: 100%;
  }

  &--rounded {
    border-radius: $border-radius-default;
  }

  &--circle {
    border-radius: 50%;
  }
}
