@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.icon-brand {
  &--white * {
    fill: #fff;
    transition: 700ms;
  }

  &--primary * {
    fill: $primary-color;
    transition: 700ms;
  }

  &--secondary * {
    fill: $secondary-color;
    transition: 700ms;
  }

  &--gray * {
    fill: $gray-color;
    transition: 700ms;
  }
}
