@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.medium-image-banner-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-grey-color-100;
  transition: 200ms;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  text-decoration: none;
  height: 99px;
  width: 343px;

  &__container {
    width: 343px;
  }

  &__figure {
    height: 70%;
    width: 90%;
  }
}
