@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.toggle-chip {
  height: 32px;
  background: $white-color;
  border: 1px solid $footer-border-color;
  border-radius: 16px;
  padding: 5px 16px;
  color: $text-color;
  cursor: pointer;
  min-width: rem(86);

  * {
    vertical-align: middle;
  }

  > span + svg {
    margin-right: -5px;
  }

  > svg:first-child {
    margin-left: -5px;
  }

  span {
    font-size: rem(14);
  }

  svg {
    max-height: 20px;
    max-width: 20px;
  }

  @include mq('tablet') {
    height: 40px;
    border-radius: 20px;
    padding: 9px 20px;
  }

  &__icon {
    color: $text-color;

    &-left {
      margin-right: 5px;
    }

    &-right {
      margin-left: 5px;
    }
  }

  &:hover {
    background-color: $light-grey-color-200;
    transition: 0.6s;
  }

  &--active {
    color: $primary-color;
    background: $bubblegum;
    border: 1px solid $bubblegum;

    svg {
      color: $primary-color;

      @include colorize-icon($primary-color);
    }

    &:hover {
      background-color: $bubblegum-darken;
    }
  }
}
