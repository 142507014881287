@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.overlay {
  background-color: rgb(255 255 255 / 90%);
  z-index: $overlay-z-index;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  margin: auto;
  opacity: 0;

  &--visible {
    visibility: visible;
    opacity: 1;
    transition: 300ms $ease-out-quad;
  }
}
