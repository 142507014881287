@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.catalog-item-loading {
  border: 1px solid $light-grey-color-100;
  transition: 200ms;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  text-decoration: none;
  width: 100%;
  margin: 20px 0;
  height: 230px;

  @include mq('desktop-s') {
    padding: $padding-default;
    height: 304px;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, minmax(136px, 1fr));
    grid-gap: 10px;
    margin: 0;

    @include mq('mobile-l') {
      grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    }

    @include mq('tablet') {
      grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
      grid-gap: 20px;
    }

    @include mq('desktop-s') {
      grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
      grid-gap: 30px;
    }
  }

  &__figure {
    height: 94px;

    @include mq('mobile-l') {
      height: 110px;
    }
  }

  &__content {
    padding: 12px;
    margin-top: 4px;
  }

  &__header {
    width: 85%;
    height: 27px;
    margin-bottom: 6px;
  }

  &__info {
    width: 65%;
    height: 24px;
    margin-top: 10px;
  }

  &__footer {
    width: 45%;
    margin-top: 10px;
    height: 24px;
  }
}
