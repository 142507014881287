@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.take-X-pay-Y-tag {
  border-radius: $border-radius-default;
  display: inline-flex;
  margin-top: 3.5px;

  @include mq('tablet') {
    height: 19px;
  }

  &__title {
    font-size: rem(10);
    line-height: 130%;
    font-weight: 500;
    border: 0;
    background-color: initial;
    vertical-align: middle;
    margin: 1px 4px;
    white-space: unset;

    @include mq('tablet') {
      font-size: rem(12);
    }
  }

  &__description {
    font-size: rem(12);
    color: $text-color;
    left: -5px;
    font-weight: 500;
  }
}

.take-X-pay-Y-tag-tooltip {
  &__container {
    max-width: 320px;
    font-size: rem(14);
    transform: translateY(-10px);
  }

  &__content {
    font-size: rem(12);
    color: initial;
  }
}
