@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.quick-total-price {
  &__modal {
    padding: 20px;
    text-align: center;
    overflow: hidden;

    @include mq('tablet') {
      min-width: 600px;
    }

    button {
      margin-top: 32px;
      width: 100%;

      @include mq('tablet') {
        width: inherit;
      }
    }
  }

  &__content-wrapper {
    padding: 10px;
  }

  &__title {
    font-size: rem(16);
    font-weight: 500;
    padding-bottom: 8px;
    color: $text-color-super-darken;
    margin: 0;
  }

  &__description {
    padding-bottom: 12px;
  }

  &__description,
  &__values {
    font-size: rem(14);
    color: $input-color;
    font-weight: 300;
    display: block;
    margin: 0;
  }

  &__values {
    display: flex;
    justify-content: space-between;
  }
}
