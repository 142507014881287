@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.message-cardstack-info {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: $space-green;

  @include mq('desktop-s') {
    display: block;
    height: auto;
  }
}
