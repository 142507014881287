@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-list-carousel-loading {
  $logo-size: rem(48);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;

  @include mq('desktop-s') {
    grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
    gap: 40px;
  }

  &__merchant {
    text-decoration: none;
    display: grid;
    column-gap: rem(12);
    grid-template: 'logo header';
    grid-template-columns: $logo-size;

    @include mq('desktop-s') {
      grid-template-columns: $logo-size 100%;
    }

    &-logo {
      grid-area: logo;
      width: $logo-size;
      height: $logo-size;
      border-radius: rem(100);
    }

    &-header {
      grid-area: header;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      width: 80%;
    }

    &-description {
      margin-top: 2px;
      width: 60%;
    }
  }

  &__context {
    margin-top: rem(10);
  }

  &__items {
    margin-top: rem(12);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    gap: 20px;
    overflow-x: hidden;
  }

  &__item {
    &-image {
      width: 100%;
      height: rem(90);
      object-fit: cover;
    }

    &-title {
      margin-top: rem(8);
    }

    &-footer {
      margin: 0;
      margin-top: rem(6);
      width: 80%;
    }
  }
}
