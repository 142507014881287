@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.btn-icon {
  padding: 0;
  width: 40px;
  height: 40px;
  background: $primary-color;
  color: $secondary-color;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 3px 5px;
  border-radius: 100%;
  outline: none;
  transition: 100ms;
  position: relative;
  overflow: hidden;
  cursor: default;

  @include colorize-icon($secondary-color);

  @include mq('tablet', 'max') {
    width: 30px;
    height: 30px;
    margin: 5px 0;
  }

  @include mq('desktop-s') {
    cursor: pointer;
  }

  // for the touch to detect the ripple up in span inside the button it is necessary this z-index;
  & > canvas {
    z-index: 1;
    color: $primary-color;

    @include mq('tablet') {
      visibility: hidden;
    }
  }

  @include mq('desktop-s') {
    &:hover:enabled {
      background: color.adjust($primary-color, $lightness: 10%);
      cursor: pointer;
    }

    &:active:enabled {
      opacity: 0.9;
    }
  }

  .icon-marmita {
    height: 20px;
    width: 20px;
  }

  &.btn-icon--secondary {
    background: $secondary-color;
    color: $primary-color;

    @include colorize-icon($primary-color);

    & > canvas {
      color: color.adjust($secondary-color, $lightness: 60%);
    }

    @include mq('desktop-s') {
      &:hover:enabled {
        background: color.adjust($secondary-color, $lightness: 10%);
      }
    }
  }

  &.btn-icon--white {
    background: #fff;
    color: $primary-color;

    @include colorize-icon($primary-color);

    @include mq('desktop-s') {
      &:hover:enabled {
        background: color.adjust(#fff, $lightness: 10%);
      }
    }
  }

  &.btn-icon--gray {
    background: #fff;
    color: $gray-color;

    @include mq('desktop-s') {
      &:hover:enabled {
        background: color.adjust($gray-color, $lightness: 10%);
      }
    }
  }

  &.btn-icon--transparent {
    background: transparent;
    color: $primary-color;

    @include colorize-icon($primary-color);

    @include mq('desktop-s') {
      &:hover:enabled {
        background: rgb(255 255 255 / 30%);
      }
    }

    &.btn-icon--secondary {
      color: $secondary-color;

      @include colorize-icon($secondary-color);
    }

    &.btn-icon--tertiary {
      color: $tertiary-color;

      @include colorize-icon($tertiary-color);
    }

    &.btn-icon--white {
      color: #fff;

      @include colorize-icon(#fff);
    }

    &.btn-icon--gray {
      color: $gray-color-lighten;

      @include colorize-icon($gray-color-lighten);
    }
  }

  // Sizes
  &.btn-icon--size-s {
    width: 30px;
    height: 30px;

    .icon-marmita {
      transform: scale(0.8);

      @include mq('desktop-s') {
        transform: scale(1);
      }
    }
  }

  &.btn-icon--size-l {
    width: 50px;
    height: 50px;

    .icon-marmita {
      transform: scale(1.5);
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: no-drop;

    @include colorize-icon($gray-color-lighten);

    & > canvas {
      visibility: hidden;
    }
  }
}
