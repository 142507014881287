@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.drawer {
  position: fixed;
  z-index: 10001;
  pointer-events: none;

  @include mq('desktop-s') {
    z-index: $drawer-z-index;
  }

  &__overlay {
    pointer-events: all;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include mq('desktop-s') {
      top: 80px;
    }

    &--after-open {
      animation: fade-overlay-in 0.5s forwards;

      @include mq('desktop-s') {
        animation: unset;
      }
    }

    &--before-close {
      animation: fade-overlay-out 0.5s forwards;

      @include mq('desktop-s') {
        animation: unset;
      }
    }
  }

  &__close-button {
    width: 24px;
    height: 24px;
    min-height: 24px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    align-self: flex-end;
    position: absolute;
    left: 16px;
    z-index: 1;
    top: 4px;

    @include mq('desktop-s') {
      align-self: unset;
    }
  }

  &__content {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70%;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    transform: translateY(100%);
    border-radius: 10px 10px 0 0;
    padding: 20px;
    display: flex;
    flex-direction: column;

    @include mq('desktop-s') {
      width: 472px;
      max-height: 100vh;
      transform: translateX(100%);
      border-radius: unset;
      position: fixed;
      left: unset;
      height: calc(100vh - 80px);
      padding: 15px 30px;
    }

    @media (min-height: 800px) {
      padding: 30px;
    }

    &--after-open {
      animation: slide-in-from-bottom 0.5s forwards;

      @include mq('desktop-s') {
        animation: slide-in-from-right 0.5s forwards;
      }
    }

    &--before-close {
      animation: slide-out-to-bottom 0.5s forwards;

      @include mq('desktop-s') {
        animation: slide-out-to-right 0.5s forwards;
      }
    }

    &-container {
      flex: 1;
      overflow-y: auto;
    }
  }

  &-cart {
    .drawer__content {
      padding: 0;
    }
  }
}

@keyframes slide-in-from-right {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out-to-right {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-in-from-bottom {
  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-out-to-bottom {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes fade-overlay-in {
  100% {
    background-color: rgb(0 0 0 / 50%);
  }
}

@keyframes fade-overlay-out {
  0% {
    background-color: rgb(0 0 0 / 50%);
  }

  100% {
    background-color: rgb(0 0 0 / 0%);
  }
}
