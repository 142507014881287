@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cart-fee {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__description {
    font-size: rem(14);
    color: $medium-grey-color;

    @include mq('tablet') {
      color: $text-color;
    }
  }

  &__button {
    width: 20px;
    height: 20px;
    border-radius: 100%;

    &::before {
      content: '';
      height: 17px;
      width: 17px;
      border-radius: 100%;
      display: block;
      background: transparent;
      position: absolute;
      border: 1px solid $medium-grey-color;
    }
  }

  &__value {
    font-size: rem(15);
    color: $medium-grey-color;

    @include mq('tablet') {
      color: $black-color;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px $padding-default;

    @include mq('tablet') {
      width: 450px;
      padding: 40px 50px;
    }

    &-title {
      font-size: rem(18);
      font-weight: 500;

      @include mq('tablet') {
        font-size: rem(22);
        text-align: left;
      }
    }

    &-description {
      font-weight: lighter;
      line-height: 18px;
      font-size: rem(14);
      padding: $padding-default 0;

      @include mq('tablet') {
        font-size: rem(16);
      }
    }
  }
}
