@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.app-shell {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 $padding-default;

  .cancel-padding {
    left: -$padding-default;
    position: relative;
    width: 100vw;
  }
}
