@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-hint {
  z-index: $hint-z-index;
  display: flex;
  flex-direction: column;
  width: 335px;
  background-color: white;
  box-shadow: 0 0 12px rgb(0 0 0 / 20%);
  border-radius: $border-radius-default;
  padding: 21px 24px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms $ease-out-quad, visibility 200ms $ease-out-quad;
  transition-delay: 200ms;
  border: 1px solid #f7f7f7;

  &--visible {
    transition: opacity 200ms $ease-out-quad, visibility 200ms $ease-out-quad;
    transition-delay: 200ms;
    opacity: 1;
    visibility: visible;
  }

  &--top {
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid white;
      border-radius: 3px;
      left: 50px;
      bottom: -10px;
    }
  }

  &--bottom {
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid white;
      border-radius: 3px;
      left: 50px;
      top: -10px;
    }
  }

  &__title {
    font-size: em(16);
    line-height: em(22);
  }

  &__description {
    font-size: em(14);
    line-height: em(22);
    font-weight: 100;
    color: $text-color;
  }

  &__actions {
    display: flex;
    margin-top: 16px;

    .btn--link {
      padding: 0 12px;
      border: 1px solid $primary-color;
      margin-right: 7.5px;
      height: 32px;

      .btn__label {
        font-size: em(12);
      }
    }

    .btn--default {
      padding: 0 12px;
      margin-left: 7.5px;
      height: 32px;

      .btn__label {
        font-size: em(12);
      }
    }
  }
}
