@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
@keyframes sticky-display-progress {
  0% {
    max-height: 0;
    padding-top: 0;
  }

  100% {
    max-height: 50px;
    padding-top: 10px;
  }
}

@keyframes sticky-display-completed {
  0%,
  90% {
    max-height: 50px;
    padding-top: 10px;
  }

  100% {
    max-height: 0;
    padding-top: 0;
  }
}

.delivery-discount-with-min-order {
  padding: 12px 0;
  font-weight: 300;
  font-size: rem(12);
  line-height: rem(16);
  position: relative;

  @include mq('desktop-s', max) {
    margin-top: 10px;

    @include thin-border-before;

    &::before {
      width: 100%;
      left: 0;
    }
  }

  @include mq('desktop-s') {
    padding: 24px 50px;
    background-color: $light-grey-color-200;
    font-weight: 300;
    font-size: rem(16);
    line-height: rem(20);
  }

  .icon-marmita {
    display: none;
  }

  strong {
    color: $promotion-tag-color;
  }

  &__progress {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: $light-grey-color-300;
    position: relative;

    @include mq('desktop-s') {
      height: 5px;
      border-radius: 5px;
    }
  }

  &__progress-value {
    position: absolute;
    height: 3px;
    border-radius: 3px;
    background-color: $promotion-tag-color;
    transition: all 300ms ease;
    width: 0;

    @include mq('desktop-s') {
      height: 5px;
      border-radius: 5px;
    }
  }

  &__message {
    text-align: center;
    height: rem(16);
    margin-bottom: 8px;
    overflow: hidden;
    color: $text-color;

    @include mq('desktop-s') {
      text-align: left;
      height: rem(20);
      margin-bottom: 12px;
    }

    p {
      margin: 0;
      overflow: hidden;
      transition: all 300ms ease;
    }

    > :first-child {
      max-height: 9000px;
      opacity: 1;
      visibility: visible;
    }

    > :last-child {
      max-height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__message--completed {
    > :first-child {
      max-height: 0;
      opacity: 0;
      visibility: hidden;
    }

    > :last-child {
      max-height: 9000px;
      opacity: 1;
      visibility: visible;
    }
  }

  &--completed {
    strong {
      color: $green-color;
    }

    .delivery-discount-with-min-order__progress-value {
      background-color: $green-color;
    }
  }

  &--description-only {
    background-color: $light-grey-color-200;
    padding: 10px 14px;
    border-radius: $border-radius-default;
    margin: 0 0 10px;

    &::before {
      display: none;
    }
  }

  &--menu {
    background-color: $light-grey-color-200;
    padding: 10px 14px;
    border-radius: $border-radius-default;
    margin: 0 0 10px;

    p {
      text-align: left;
    }

    .icon-marmita {
      display: inline-flex;
      height: 11.5px;
      width: 11.5px;
      transform: translateY(2px);
      margin-right: 8px;

      @include colorize-icon($promotion-tag-color);
    }

    @include mq('desktop-s') {
      width: fit-content;
    }

    &::before {
      display: none;
    }
  }

  &--sticky {
    display: none;
    overflow: hidden;
    margin: 0;
    max-height: 0;
    padding: 0;

    p {
      margin: 0 14px;
      text-align: center;
    }
  }

  &--is-sticky {
    display: block;
    animation: sticky-display-progress 200ms linear 250ms forwards;
  }

  &--completed-sticky {
    display: block;
    max-height: 50px;
    padding-top: 10px;
    animation: sticky-display-completed 2000ms linear 250ms forwards;
  }

  &__min-order-description {
    display: flex;
    flex-direction: row;

    p {
      margin: 0;
    }

    strong {
      color: $promotion-tag-color;
    }

    .icon-marmita {
      display: inline-flex;
      height: 11.5px;
      width: 11.5px;
      transform: translateY(2px);
      margin-right: 8px;

      @include colorize-icon($promotion-tag-color);
    }
  }
}
