@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cardstack-image {
  display: block;
  position: relative;

  &__status {
    position: absolute;
    margin: 0;
    background: rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12);
    line-height: rem(20);
    color: #fff;
    text-align: center;

    @include mq('tablet') {
      font-size: rem(16);
    }
  }

  &__no-img {
    overflow: hidden;
    background-color: $light-grey-color-100;
    z-index: -1;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;

    svg {
      width: 100%;
    }
  }

  &__DEFAULT {
    border-radius: 0;
  }

  &__ROUND {
    border-radius: 50rem;
  }

  &__BORDERED {
    border-radius: 0.8rem;
  }
}
