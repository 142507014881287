@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.marmita-scrollbox {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 145px;

  &--dragging {
    a {
      pointer-events: none;
    }

    cursor: grabbing;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 17px;
    box-sizing: content-box;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    height: 100%;
    border-radius: 5px;
    position: relative;
    display: inline-flex;

    & > * {
      margin-right: rem(20);

      @media screen and (max-width: 767px) {
        &:first-child {
          margin-left: $padding-default;
        }

        &:last-child {
          margin-right: $padding-default;
        }
      }
    }
  }

  &__button {
    display: none;
    position: absolute;
    top: 25%;

    @include mq('tablet', min) {
      display: block;
      top: unset;
      bottom: 0;
    }

    &--left {
      left: 15px;

      @include mq('tablet', min) {
        left: 0;
      }
    }

    &--right {
      right: 15px;
      transform: rotate(180deg);

      @include mq('tablet', min) {
        right: 0;
      }
    }
  }
}
